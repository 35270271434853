import AppDispatcher from "../dispatchers/AppDispatcher";

export const SET_USER = 'SET_USER';
export const SET_READER = 'SET_READER';

export function setUser(user) {
    AppDispatcher.dispatch({
        type: SET_USER,
        user: user
    });
}

export function setReader(reader) {
    AppDispatcher.dispatch({
        type: SET_READER,
        reader: reader
    });
}