import AppDispatcher from "../dispatchers/AppDispatcher";

export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const SET_SPINNER = 'SET_SPINNER';
export const SHOW_ERROR_DIALOG = 'SHOW_ERROR_DIALOG';

export function setActivePage(activePage) {
    AppDispatcher.dispatch({
        type: SET_ACTIVE_PAGE,
        activePage: activePage
    });
}

export function setSpinner(spinner) {
    AppDispatcher.dispatch({
        type: SET_SPINNER,
        spinner: spinner
    });
}

export function showErrorDialog(errorDialog) {
    AppDispatcher.dispatch({
        type: SHOW_ERROR_DIALOG,
        errorDialog: errorDialog
    });
}