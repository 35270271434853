import React, {Component} from "react";

import {getGsmfHistory, gsmfSearchMerchants, gsmfUpsertMerchants} from "../../api/CoreHandlerApi";
import {
    Alert,
    Button,
    ButtonGroup,
    ButtonToolbar,
    Card,
    Col,
    Form,
    Modal,
    OverlayTrigger,
    Pagination,
    Popover,
    Row,
    Tab,
    Table,
    Tabs,
    Tooltip
} from "react-bootstrap";
import {TrashIcon} from "@primer/octicons-react";
import * as XLSX from "xlsx";

const MERCHANTS_PAGE_SIZE = 25;

const TAB = {
    SEARCH: 1,
    FILE_PROCESSING: 2
};

export class GsmfOperationsPanel extends Component {
    constructor(props) {
        super(props);

        this.fetchMerchantsPage = this.fetchMerchantsPage.bind(this);
        this.onNewMerchantsPage = this.onNewMerchantsPage.bind(this);
        this.onMerchantsPageSelect = this.onMerchantsPageSelect.bind(this);
        this.onError = this.onError.bind(this);
        this.renderPagination = this.renderPagination.bind(this);
        this.closeGsmfProcessConfirmationDialog = this.closeGsmfProcessConfirmationDialog.bind(this);
        this.onProcessedMerchant = this.onProcessedMerchant.bind(this);
        this.onClickGsmfSearch = this.onClickGsmfSearch.bind(this);
        this.clearGsmfSearchCriteriaFields = this.clearGsmfSearchCriteriaFields.bind(this);
        this.handleMerchantFile = this.handleMerchantFile.bind(this);

        this.state = {
            totalCountOfMerchants: 0,
            merchantsPageSize: MERCHANTS_PAGE_SIZE,
            currentMerchantsPage: 1,
            merchants: undefined,
            gsmfProcessConfirmation: undefined,
            selectedUser: undefined
        }
    }

    componentDidMount() {
        this.fetchMerchantsPage(1);
    }

    fetchMerchantsPage(pageNumber) {
        let pageSize = MERCHANTS_PAGE_SIZE;
        let offset = ((pageNumber - 1) * pageSize);

        let searchCriteria = this.getGsmfSearchCriteria();

        gsmfSearchMerchants({offset, pageSize, ...searchCriteria})
            .then(this.onNewMerchantsPage)
            .catch(this.onError);
    }

    onNewMerchantsPage(resp) {
        this.setState({
            totalCountOfMerchants: resp.totalCount,
            merchantsMap: resp.users.reduce((acc, u) => ({...acc, [u.usua_idsuario]: u}), {})
        });
    }

    onMerchantsPageSelect(currentMerchantsPage) {
        this.setState({currentMerchantsPage: currentMerchantsPage});
        this.fetchMerchantsPage(currentMerchantsPage);
    }

    onError(e) {
        console.error(e)
    }

    renderPagination() {
        const {totalCountOfMerchants, merchantsPageSize, currentMerchantsPage} = this.state;

        // Do not render pagination if totalCount is 0
        if (totalCountOfMerchants === 0) {
            return null;
        }

        const totalPages = Math.ceil(totalCountOfMerchants / merchantsPageSize);
        let items = [];
        let startPage, endPage;

        if (totalPages <= 5) {
            // Less than 5 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // More than 5 total pages so calculate start and end pages
            if (currentMerchantsPage <= 3) {
                startPage = 1;
                endPage = 5;
            } else if (currentMerchantsPage + 2 >= totalPages) {
                startPage = totalPages - 4;
                endPage = totalPages;
            } else {
                startPage = currentMerchantsPage - 2;
                endPage = currentMerchantsPage + 2;
            }
        }

        // Add the first page and ellipsis if necessary
        if (startPage > 1) {
            items.push(<Pagination.First key="first" onClick={() => this.onMerchantsPageSelect(1)}/>);
            items.push(<Pagination.Ellipsis key="ellipsis-first"/>);
        }

        // Add page number links
        for (let page = startPage; page <= endPage; page++) {
            items.push(
                <Pagination.Item key={page} active={page === currentMerchantsPage}
                                 onClick={() => this.onMerchantsPageSelect(page)}>
                    {page}
                </Pagination.Item>
            );
        }

        // Add the last page and ellipsis if necessary
        if (endPage < totalPages) {
            items.push(<Pagination.Ellipsis key="ellipsis-last"/>);
            items.push(<Pagination.Last key="last" onClick={() => this.onMerchantsPageSelect(totalPages)}/>);
        }

        return <Pagination>{items}</Pagination>;
    }

    setSelectedUser = (user) => {
        this.setState({selectedUser: user});
    }

    async onShowUserHistory(user) {
        const historyResp = await getGsmfHistory(user.usua_idsuario);

        if (historyResp && historyResp.history) {
            this.setState(prevState => ({
                merchantsMap: {
                    ...prevState.merchantsMap,
                    [user.usua_idsuario]: {
                        ...prevState.merchantsMap[user.usua_idsuario],
                        history: historyResp.history
                    }
                }
            }), () => {
                this.setSelectedUser(this.state.merchantsMap[user.usua_idsuario]);
            });
        }
    }

    renderMerchantsTable() {
        const {merchantsMap} = this.state;

        if (!(merchantsMap && typeof merchantsMap === 'object')) {
            return null;
        }

        let merchants = (merchantsMap && typeof merchantsMap === 'object') ? Object.values(merchantsMap) : [];
        let getRowStyle = (u) => {
            if (u.gsmf_user_status === 'UPSERT' && u.gsmf_process_status === 'NEW') {
                return {backgroundColor: '#94deff', color: 'black'};
            } else if (u.gsmf_user_status === 'SUSPEND' && u.gsmf_process_status === 'NEW') {
                return {backgroundColor: '#e86565', color: 'white'};
            } else return {};
        }

        let getCellStyle = (u) => {
            let style = {};
            if (u.gsmf_history_latest_user_status === 'SUSPEND') {
                style = {backgroundColor: '#e86565', color: 'white'};
            } else if (u.gsmf_history_latest_user_status === 'UPSERT') {
                style = {backgroundColor: '#94deff', color: 'black'};
            } else {
                style = {backgroundColor: 'white', color: 'black'};
            }
            return style;
        }

        let nonProcessableStyle = {backgroundColor: '#fff7a9', color: '#000000'};

        return (
            <Table striped bordered hover>
                <thead className="thead-dark">
                <tr>
                    <th>User Id</th>
                    <th>Email</th>
                    <th>Nombre</th>
                    <th>Razón Social</th>
                    <th>Nombre Comercial</th>
                    <th>Giro</th>
                    <th>GSMF operation</th>
                    <th>Estatus actual GSMF</th>
                </tr>
                </thead>
                <tbody>
                {
                    merchants.map(u => {
                        let errors = this.getGsmfMissingFields(u);
                        let isProcessable = errors.length === 0;

                        return (
                            <tr key={u.usua_idsuario}
                                style={isProcessable ? getRowStyle(u) : nonProcessableStyle}>
                                <td>{u.usua_idsuario}</td>
                                <td>{u.usua_correo}</td>
                                <td>{this.getGsmfMerchantOwnerFullName(u)}</td>
                                <td>{u.usua_razonsocial}</td>
                                <td>{u.usua_nombrecomercial}</td>
                                <td>{u.giro_descripciongiro}</td>
                                <td>{this.upsertGsmfButton(u)}</td>
                                <td style={isProcessable ? getCellStyle(u) : nonProcessableStyle}>
                                    {isProcessable ? u.gsmf_history_latest_user_status : (
                                        <OverlayTrigger
                                            trigger={'click'}
                                            placement="left"
                                            overlay={
                                                <Popover id={`popover-${u.usua_idsuario}`}>
                                                    <Popover.Title
                                                        as="h3">{`CAMPOS FALTANTES, usuario: ${u.usua_idsuario}`}</Popover.Title>
                                                    <Popover.Content>
                                                        <ul>
                                                            {errors.map((error, index) => (
                                                                <li key={index}>{error}</li>
                                                            ))}
                                                        </ul>
                                                    </Popover.Content>
                                                </Popover>
                                            }
                                        >
                                            <Button variant="warning">INVÁLIDO</Button>
                                        </OverlayTrigger>
                                    )}
                                </td>
                            </tr>
                        );
                    })
                }
                </tbody>
            </Table>
        );
    }

    renderGsmfProcessConfirmationDialog = () => {
        const {gsmfProcessConfirmation} = this.state;

        let merchant = gsmfProcessConfirmation && gsmfProcessConfirmation['merchant'] !== undefined ? gsmfProcessConfirmation['merchant'] : null;
        let operation = gsmfProcessConfirmation && gsmfProcessConfirmation['operation'] !== undefined ? gsmfProcessConfirmation['operation'] : null;
        let headerText = gsmfProcessConfirmation && gsmfProcessConfirmation['headerText'] !== undefined ? gsmfProcessConfirmation['headerText'] : 'N/D';
        let bodyText = gsmfProcessConfirmation && gsmfProcessConfirmation['bodyText'] !== undefined ? gsmfProcessConfirmation['bodyText'] : 'N/D';
        let buttonText = gsmfProcessConfirmation && gsmfProcessConfirmation['buttonText'] !== undefined ? gsmfProcessConfirmation['buttonText'] : 'N/D';

        return (
            <Modal
                show={merchant != null}
                onHide={() => this.closeGsmfProcessConfirmationDialog()}
                backdrop='static'
                keyboard={true}>
                {
                    headerText != null ?
                        <Modal.Header>
                            <Modal.Title>
                                <Alert variant='dark'>
                                    <Alert.Heading>{headerText}</Alert.Heading>
                                </Alert>
                            </Modal.Title>
                        </Modal.Header> : null
                }
                <Modal.Body>
                    {bodyText && bodyText.split('\n').map((line, index) => (
                        <span key={index}>
                            {line}
                            <br/>
                        </span>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                            onClick={() => this.closeGsmfProcessConfirmationDialog()}>Cancelar</Button>
                    <Button variant="primary" onClick={() => operation()}>{buttonText}</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderGsmfSearchPanel() {
        return (
            <Card>
                <Card.Body>
                    <Form>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1">Nombre comercial</Form.Label>
                            <Col sm="4">
                                <Form.Control
                                    type="text"
                                    onChange={val => this.onChangeGsmfSearchCriteria('scMerchantName', val.target.value)}
                                    value={this.state.scMerchantName || ''}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1">Razón social</Form.Label>
                            <Col sm="4">
                                <Form.Control
                                    type="text"
                                    onChange={val => this.onChangeGsmfSearchCriteria('scLegalName', val.target.value)}
                                    value={this.state.scLegalName || ''}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1">Email</Form.Label>
                            <Col sm="4">
                                <Form.Control
                                    type="text"
                                    onChange={val => this.onChangeGsmfSearchCriteria('scEmail', val.target.value)}
                                    value={this.state.scEmail || ''}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1">Nombre</Form.Label>
                            <Col sm="2">
                                <Form.Control
                                    type="text"
                                    onChange={val => this.onChangeGsmfSearchCriteria('scName', val.target.value)}
                                    value={this.state.scName || ''}
                                />
                            </Col>
                            <Form.Label column sm="0">Apellido 1</Form.Label>
                            <Col sm="2">
                                <Form.Control
                                    type="text"
                                    onChange={val => this.onChangeGsmfSearchCriteria('scLastName1', val.target.value)}
                                    value={this.state.scLastName1 || ''}
                                />
                            </Col>
                            <Form.Label column sm="0">Apellido 2</Form.Label>
                            <Col sm="2">
                                <Form.Control
                                    type="text"
                                    onChange={val => this.onChangeGsmfSearchCriteria('scLastName2', val.target.value)}
                                    value={this.state.scLastName2 || ''}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1">GSMF Id</Form.Label>
                            <Col sm="4">
                                <Form.Control
                                    type="text"
                                    onChange={val => this.onChangeGsmfSearchCriteria('scUserId', val.target.value)}
                                    value={this.state.scUserId || ''}
                                />
                            </Col>
                            <Form.Label column sm="0">Fitros GSMF</Form.Label>
                            <Col sm="2">
                                <Form.Control
                                    as="select"
                                    onChange={val => this.onChangeGsmfSearchCriteria('scGsmfHistoryStatus', val.target.value)}
                                    value={this.getHistoryStatus() || ''}
                                >
                                    <option value="ALL">TODOS</option>
                                    <option value="IN_GSMF">GSMF</option>
                                    <option value="NOT_IN_GSMF">NO GSMF</option>
                                    <option value="UPSERT">UPSERT</option>
                                    <option value="SUSPEND">SUSPEND</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1">Giro</Form.Label>
                            <Col sm="4">
                                <Form.Control
                                    type="text"
                                    onChange={val => this.onChangeGsmfSearchCriteria('scMccDesc', val.target.value)}
                                    value={this.state.scMccDesc || ''}
                                />
                            </Col>
                        </Form.Group>
                        <ButtonToolbar>
                            <ButtonGroup className="mr-2">
                                <OverlayTrigger overlay={<Tooltip>Limpiar filtros</Tooltip>}>
                                    <Button variant='primary'
                                            onClick={this.clearGsmfSearchCriteriaFields}><TrashIcon/></Button>
                                </OverlayTrigger>
                            </ButtonGroup>
                            <ButtonGroup className="mr-2">
                                <Button onClick={this.onClickGsmfSearch}>
                                    Buscar comercios
                                </Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Form>
                </Card.Body>
            </Card>
        );
    }

    onChangeGsmfSearchCriteria(field, value) {
        switch (field) {
            case 'scMerchantName':
                this.setState({scMerchantName: value});
                break;
            case 'scLegalName':
                this.setState({scLegalName: value});
                break;
            case 'scEmail':
                this.setState({scEmail: value});
                break;
            case 'scName':
                this.setState({scName: value});
                break;
            case 'scLastName1':
                this.setState({scLastName1: value});
                break;
            case 'scLastName2':
                this.setState({scLastName2: value});
                break;
            case 'scUserId':
                this.setState({scUserId: value});
                break;
            case 'scMccDesc':
                this.setState({scMccDesc: value});
                break;
            case 'scGsmfHistoryStatus':
                this.setHistoryStatus(value)
                break;
            default:
                break;
        }
    }

    setHistoryStatus(status) {
        switch (status) {
            case 'ALL':
                this.setState({scHasGsmfHistory: undefined, scGsmfHistoryLatestStatus: undefined});
                break;
            case 'IN_GSMF':
                this.setState({scHasGsmfHistory: true, scGsmfHistoryLatestStatus: undefined});
                break;
            case 'NOT_IN_GSMF':
                this.setState({scHasGsmfHistory: false, scGsmfHistoryLatestStatus: undefined});
                break;
            case 'UPSERT':
                this.setState({scHasGsmfHistory: true, scGsmfHistoryLatestStatus: 'UPSERT'});
                break;
            case 'SUSPEND':
                this.setState({scHasGsmfHistory: true, scGsmfHistoryLatestStatus: 'SUSPEND'});
                break;
            default:
                this.setState({scHasGsmfHistory: undefined, scGsmfHistoryLatestStatus: undefined});
                break;
        }
    }

    getHistoryStatus() {
        if (this.state.scHasGsmfHistory === undefined && this.state.scGsmfHistoryLatestStatus === undefined) {
            return 'ALL'
        } else if (this.state.scHasGsmfHistory === true && this.state.scGsmfHistoryLatestStatus === undefined) {
            return 'IN_GSMF';
        } else if (this.state.scHasGsmfHistory === false && this.state.scGsmfHistoryLatestStatus === undefined) {
            return 'NOT_IN_GSMF';
        } else if (this.state.scHasGsmfHistory === true && this.state.scGsmfHistoryLatestStatus === 'UPSERT') {
            return 'UPSERT';
        } else if (this.state.scHasGsmfHistory === true && this.state.scGsmfHistoryLatestStatus === 'SUSPEND') {
            return 'SUSPEND';
        } else {
            return 'ALL';
        }
    }

    onClickGsmfSearch() {
        this.fetchMerchantsPage(1);
    }

    getGsmfSearchCriteria() {
        const {
            scMerchantName,
            scLegalName,
            scEmail,
            scName,
            scLastName1,
            scLastName2,
            scUserId,
            scMccDesc,
            scHasGsmfHistory,
            scGsmfHistoryLatestStatus
        } = this.state;
        return {
            merchantName: scMerchantName,
            legalName: scLegalName,
            email: scEmail,
            name: scName,
            lastName1: scLastName1,
            lastName2: scLastName2,
            userId: scUserId,
            mccDesc: scMccDesc,
            hasGsmfHistory: scHasGsmfHistory,
            gsmfHistoryLatestStatus: scGsmfHistoryLatestStatus
        };
    }

    clearGsmfSearchCriteriaFields() {
        this.setState({
            scMerchantName: undefined,
            scLegalName: undefined,
            scEmail: undefined,
            scName: undefined,
            scLastName1: undefined,
            scLastName2: undefined,
            scUserId: undefined,
            scMccDesc: undefined,
            scHasGsmfHistory: undefined,
            scGsmfHistoryLatestStatus: undefined
        }, () => this.fetchMerchantsPage(1));
    }

    closeGsmfProcessConfirmationDialog = () => {
        this.setState({
            gsmfProcessConfirmation: undefined
        });
    }

    upsertGsmfButton = (u) => {
        let errors = this.getGsmfMissingFields(u);
        let isProcessable = errors.length === 0;
        let userName = this.getGsmfMerchantOwnerFullName(u);
        let userId = u.usua_idsuario;
        let hasGsmfHistory = u.gsmf_history_latest_user_status ? true : false;

        return isProcessable ? (
            <ButtonGroup className="ml-2">
                <Button className="mr-2"
                        variant='primary'
                        onClick={() => this.gsmfProcess(u, 'UPSERT',
                            `Actualizar usuario ${userId} al GSMF`,
                            `Se Actualizará/Ingresará al comercio de ${userName} al GSMF, registrándolo en el archivo que se procesa diariamente, y cuyos cambios se verán reflejados al día siguiente (o posterior), dependiendo de la hora a la que se ingrese el registro.`,
                            `Actualizar ${userId}`)}>{`Actualizar ${u.usua_idsuario}`}</Button>
                <Button className="mr-2"
                        variant='danger'
                        onClick={() => this.gsmfProcess(u, 'SUSPEND',
                            `Suspensión del usuario ${userId} al GSMF`,
                            `Se suspenderá al comercio de ${userName}, registrándolo el cambio en el archivo que se procesa diariamente, y cuyos cambios se verán reflejados al día siguiente (o posterior), dependiendo de la hora a la que se ingrese el registro.`,
                            `Suspender ${userId}`)}>{`Suspender ${u.usua_idsuario}`}</Button>
                {hasGsmfHistory ? (<Button
                    variant='warning'
                    onClick={() => this.onShowUserHistory(u)}>{`Historial ${u.usua_idsuario}`}</Button>) : null}
            </ButtonGroup>
        ) : null;
    }

    getGsmfMerchantOwnerFullName(u) {
        let {usua_nombre, usua_apellido1, usua_apellido2} = u;

        let fullName = '';

        if (usua_nombre && usua_apellido1 && usua_apellido2) {
            fullName = `${usua_apellido1} ${usua_apellido2}, ${usua_nombre}`;
        } else if (usua_apellido1 || usua_apellido2) {
            let apellido = usua_apellido1 || usua_apellido2; // Use the one that's available
            fullName = `${apellido}, ${usua_nombre}`;
        } else if (usua_nombre) {
            fullName = usua_nombre;
        }

        return fullName.toUpperCase();
    }

    getGsmfMissingFields(u) {
        let errors = [];

        if (!u.usua_razonsocial || !u.usua_razonsocial.trim()) errors.push("Legal Name (usua_razonsocial) es requerido.");
        if (!u.usua_nombrecomercial || !u.usua_nombrecomercial.trim()) errors.push("DBA Name (usua_nombrecomercial) es requerido.");
        if (!u.usua_calle || !u.usua_calle.trim()) errors.push("Address component 1 (usua_calle) es requerido.");
        if (!u.usua_numeroexterior || !u.usua_numeroexterior.trim()) errors.push("Address component 2 (usua_numeroexterior) es requerido.");
        if (!u.usua_colonia || !u.usua_colonia.trim()) errors.push("Address component 4 (usua_colonia) es requerido.");
        if (!u.usua_municipio || !u.usua_municipio.trim()) errors.push("City Name (usua_municipio) es requerido.");
        if (!u.usua_estado || !u.usua_estado.trim()) errors.push("Region Code (usua_estado) es requerido.");
        if (!u.usua_codigopostal || !u.usua_codigopostal.trim()) errors.push("Postal Code (usua_codigopostal) es requerido.");
        if (!u.usua_rfc || !u.usua_rfc.trim()) errors.push("TIN/TSN (usua_rfc) es requerido.");
        if (!u.usua_nombre || !u.usua_nombre.trim()) errors.push("Name (usua_nombre) es requerido.");
        if (!u.usua_apellido1 || !u.usua_apellido1.trim()) errors.push("Last name 1 (usua_apellido1) es requerido.");
        if (!u.usua_fechadenacimiento) errors.push("DOB (usua_fechadenacimiento) es requerido.");
        if (!u.usua_correo || !u.usua_correo.trim()) errors.push("Email (usua_correo) es requerido.");
        if (!u.usua_telefono || !u.usua_telefono.trim()) errors.push("Phone (usua_telefono) es requerido.");
        if (u.giro_mcc === null || u.giro_mcc === undefined) errors.push("MCC (giro_mcc) es requerido.");

        return errors;
    }

    isGsmfHoldOn(u) {
        return (u.gsmf_user_status !== 'HOLD_ON' && u.gsmf_user_status !== 'PROCESSED' && u.gsmf_user_status !== 'NEW');
    }

    gsmfProcess(u, gsmfStatus, headerText, bodyText, buttonText) {
        this.setState({
            gsmfProcessConfirmation: {
                merchant: u,
                operation: () => gsmfUpsertMerchants(u.usua_idsuario, gsmfStatus)
                    .then((r) => gsmfSearchMerchants({userId: r['userId']}))
                    .then(r => r['users'][0])
                    .then(this.onProcessedMerchant)
                    .catch(this.onError),
                headerText: headerText,
                bodyText: bodyText,
                buttonText: buttonText
            }
        });
    }

    onProcessedMerchant(response) {
        this.setState(prevState => {
            let updatedMap = prevState.merchantsMap ? {...prevState.merchantsMap} : {};
            updatedMap[response['usua_idsuario']] = response;
            return {merchantsMap: updatedMap};
        }, () => {
            this.closeGsmfProcessConfirmationDialog();
            // setTimeout(() => {
            //     this.closeGsmfProcessConfirmationDialog(undefined);
            // }, 5000);
        });
    }

    formatDate(date) {
        if (date) {
            let day = date.getDate();
            day = day < 10 ? '0' + day : day;
            let month = date.getMonth() + 1;
            month = month < 10 ? '0' + month : month;
            let year = date.getFullYear();
            let hours = date.getHours();
            hours = hours < 10 ? '0' + hours : hours;
            let minutes = date.getMinutes();
            minutes = minutes < 10 ? '0' + minutes : minutes;
            let seconds = date.getSeconds();
            seconds = seconds < 10 ? '0' + seconds : seconds;

            // Format: DD-MM-YYYY HH:mm:ss
            return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
        } else {
            return date;
        }
    }

    renderUserHistory() {
        let user = this.state.selectedUser;

        if (user !== undefined) {
            return (
                <Modal
                    show={true}
                    onHide={() => this.setSelectedUser(undefined)}
                    backdrop='static'
                    keyboard={true}
                    size='xl'>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <Alert variant='dark'>
                                <Alert.Heading>{`GSMF Id: ${user.usua_idsuario} | ${this.getGsmfMerchantOwnerFullName(user)}`}</Alert.Heading>
                            </Alert>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table striped bordered hover>
                            <thead className="thead-dark">
                            <tr>
                                <th>Estatus de usuario en GSMF</th>
                                <th>Fecha de actualización</th>
                                {/*<th>Estatus archivo GSMF</th>*/}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                user.history
                                    .map(u => (
                                        <tr key={u.lastUpdate}>
                                            <td>{u.gsmfUserStatus}</td>
                                            <td>{this.formatDate(new Date(u.lastUpdate))}</td>
                                        </tr>
                                    ))
                            }
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
            );
        }
    }

    async handleMerchantFile(event) {
        const fileInput = event.target; // Store input element reference
        const file = fileInput.files[0];
        if (!file) return; // Exit if no file selected

        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, {type: 'buffer'});

        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];

        const jsonGsmfMerchantArray = XLSX.utils.sheet_to_json(worksheet, {
            header: ['id'],
            range: 1,
            raw: false,
            blankrows: false
        });

        jsonGsmfMerchantArray.forEach((row, idx) => {
            // Trim all string values in the row
            Object.keys(row).forEach(key => {
                if (typeof row[key] === 'string') {
                    row[key] = row[key].trim();
                }
            });

            row.status = 'DESCONOCIDO';
        });

        this.setState({
            gsmfMerchantMap: jsonGsmfMerchantArray.reduce((acc, tx) => ({...acc, [tx.id]: tx}), {})
        });

        // Reset the file input after processing
        fileInput.value = '';
    }

    createMerchantTableFromFile() {
        const gsmfMerchantMap = this.state.gsmfMerchantMap || {};
        const jsonGsmfMerchantArray = Object.values(gsmfMerchantMap);
        let getStatusCellStyle = (status) => {
            let style = {};

            if (status === 'SUSPEND') {
                style = {backgroundColor: '#e8ab65', color: 'black'};
            } else if (status === 'UPSERT') {
                style = {backgroundColor: '#94deff', color: 'black'};
            } else {
                style = {backgroundColor: '#fff1b6', color: 'black'};
            }

            return style;
        }

        return jsonGsmfMerchantArray.map(tx => {
            return (
                <tr style={getStatusCellStyle(tx.status)} key={tx.id}>
                    <td>{tx.id}</td>
                    <td>{tx.status}</td>
                </tr>
            );
        });
    }

    getMerchantActionButtons = () => {
        const gsmfMerchantMap = this.state.gsmfMerchantMap || {};
        const jsonGsmfMerchantArray = Object.values(gsmfMerchantMap);
        const isProcessing = this.state.isGsmfProcessing !== undefined ? this.state.isGsmfProcessing : false;

        if (jsonGsmfMerchantArray.length > 0) {
            return (
                <>
                    <div>
                        <Button variant={'success'}
                                onClick={() => this.processMerchantArray(jsonGsmfMerchantArray, 'UPSERT')}
                                disabled={isProcessing}
                        >
                            UPSERT
                        </Button>
                    </div>
                    <div>
                        <Button variant={'danger'}
                                onClick={() => this.processMerchantArray(jsonGsmfMerchantArray, 'SUSPEND')}
                                disabled={isProcessing}>
                            SUSPEND
                        </Button>
                    </div>
                </>
            );
        } else {
            return null;
        }
    }

    async processMerchantArray(jsonGsmfMerchantArray, action) {
        this.updateMerchantInJsonMap(undefined, true);

        for (const merchant of jsonGsmfMerchantArray) {
            try {
                let pageSize = 1;
                let offset = 0;
                let userId = merchant['id'];

                const completeMerchant = await gsmfSearchMerchants({offset, pageSize, userId});

                if (completeMerchant && completeMerchant.users && completeMerchant.users.length === 1) {
                    const user = completeMerchant.users[0];

                    if (this.getGsmfMissingFields(user).length === 0) {
                        // eslint-disable-next-line
                        const response = await gsmfUpsertMerchants(merchant['id'], action);
                        merchant['status'] = action;
                    } else {
                        merchant['status'] = 'INVALID';
                    }
                } else {
                    merchant['status'] = 'USER NOT FOUND';
                }
            } catch (error) {
                merchant['status'] = `ERROR TRYING TO ${action}`;
            }

            this.updateMerchantInJsonMap(merchant, true);
        }

        this.updateMerchantInJsonMap(undefined, undefined);
    }

    updateMerchantInJsonMap = (merchant, isBeingProcessed) => {
        this.setState(prevState => {
            if (merchant !== undefined) {
                const gsmfMerchantMap = prevState.gsmfMerchantMap || {};
                const updatedMap = {...gsmfMerchantMap};

                updatedMap[merchant.id] = merchant;

                return {
                    gsmfMerchantMap: updatedMap,
                    isGsmfProcessing: isBeingProcessed
                }
            } else {
                return {
                    isGsmfProcessing: isBeingProcessed
                }
            }
        });
    }

    render() {
        return (
            <Tabs defaultActiveKey={TAB.SEARCH} id="uncontrolled-tab-example">
                <Tab eventKey={TAB.SEARCH} title="Gestión de comercios en GSMF">
                    <div>
                        {this.renderUserHistory()}
                    </div>
                    <div>
                        {this.renderGsmfSearchPanel()}
                    </div>
                    <div>
                        <ButtonToolbar>
                            {this.renderPagination()}
                        </ButtonToolbar>
                    </div>
                    <div>
                        {this.renderGsmfProcessConfirmationDialog()}
                    </div>
                    <div>
                        {this.renderMerchantsTable()}
                    </div>
                </Tab>
                <Tab eventKey={TAB.FILE_PROCESSING} title="Procesamiento de comercios por archivo">
                    <Card>
                        <Card.Body>
                            <Form.Group as={Row}>
                                <Col sm="6">
                                    <Form>
                                        <Form.File id="custom-file-input" custom>
                                            <Form.File.Input accept=".xlsx, .xls, .csv"
                                                             onChange={this.handleMerchantFile}/>
                                            <Form.File.Label data-browse="Seleccionar archivo">
                                                Seleccionar (Excel or CSV)
                                            </Form.File.Label>
                                        </Form.File>
                                    </Form>
                                </Col>
                            </Form.Group>
                            <ButtonToolbar>
                                {
                                    this.getMerchantActionButtons()
                                }
                            </ButtonToolbar>
                        </Card.Body>
                    </Card>
                    <Table striped bordered hover>
                        <thead className="thead-dark">
                        <tr>
                            <th>GSMF Id</th>
                            <th>Estatus</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.createMerchantTableFromFile()}
                        </tbody>
                    </Table>
                </Tab>
            </Tabs>
        );
    }
}