import React from 'react';
import {Auth, CognitoHostedUIIdentityProvider} from '@aws-amplify/auth';
import {Hub} from '@aws-amplify/core';
import {MainPanel} from "./panels/MainPanel";
import './css/App.css';

class SecuredApp extends React.Component {
    componentDidMount() {
        Hub.listen("auth", ({payload: {event, data}}) => {
            switch (event) {
                case "signIn":
                    this.props.setLoggedUser(data);
                    break;
                case "signOut":
                    this.props.setLoggedUser(null);
                    break;
                default:
                    console.warn(`OAuth event ${event} received with payload: ${data}`);
                    break;
            }
        });

        Auth.currentAuthenticatedUser()
            .then(this.props.setLoggedUser)
            .catch(() => Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google}));
    }

    render() {
        const loggedUser = this.props.appState.loggedUser;
        return (loggedUser !== undefined ? <MainPanel {...this.props}/> : null);
    }
}

export default function App(props) {
    return <SecuredApp {...props}/>;
};
