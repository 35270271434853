import {ReduceStore} from 'flux/utils';
import AppDispatcher from '../dispatchers/AppDispatcher';
import {
    SET_ACTIVE_PAGE,
    SET_SPINNER,
    SHOW_ERROR_DIALOG
} from "../actions/UtilsActions";

const stateCallbacks = (function () {

    function setActivePage(state, action) {
        return Object.assign({}, state, {activePage: action.activePage});
    }

    function setSpinner(state, action) {
        return Object.assign({}, state, {spinner: action.spinner});
    }

    function showErrorDialog(state, action) {
        return Object.assign({}, state, {errorDialog: action.errorDialog});
    }

    return {
        [Symbol.for(SET_ACTIVE_PAGE)]: setActivePage,
        [Symbol.for(SET_SPINNER)]: setSpinner,
        [Symbol.for(SHOW_ERROR_DIALOG)]: showErrorDialog,
    }
}());

const UtilsStore = class extends ReduceStore {
    constructor() {
        super(AppDispatcher);
        this.getInitialState = this.getInitialState.bind(this);
    }

    getInitialState() {
        return {
            activePage: 1,
            spinner: false,
            errorDialog: undefined
        };
    }

    reduce(state, action) {
        if (stateCallbacks[Symbol.for(action.type)]) {
            return stateCallbacks[Symbol.for(action.type)](state, action);
        } else {
            return state;
        }
    }
};

export default new UtilsStore();