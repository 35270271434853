import AppDispatcher from '../dispatchers/AppDispatcher';

export const SET_AUDITS = 'SET_AUDITS';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_USERNAME = 'SET_USERNAME';

export function setAudits(audits) {
    AppDispatcher.dispatch({
        type: SET_AUDITS,
        audits: audits
    });
}

export function setStartDate(startDate) {
    AppDispatcher.dispatch({
        type: SET_START_DATE,
        startDate: startDate
    });
}

export function setEndDate(endDate) {
    AppDispatcher.dispatch({
        type: SET_END_DATE,
        endDate: endDate
    });
}

export function setUsername(username) {
    AppDispatcher.dispatch({
        type: SET_USERNAME,
        username: username
    });
}