import {ReduceStore} from 'flux/utils';
import AppDispatcher from '../dispatchers/AppDispatcher';
import {
    SET_PSP_ID,
    SET_PSP_SUBMITTER_ID,
    SET_PSP_NAME,
    SET_PSP_SE_NUMBER,
    SET_PSP_SPC,
    SET_PSP_COUNTRY_CODE,
    SET_PSP_SBMN_SUBMITTER_ID,
    SET_FIIDS, SET_NOTES, SET_MDA_CREDIT, SET_MDA_DEBIT
} from "../actions/AmxPspActions";

const stateCallbacks = (function () {
    function setPspId(state, action) {
        return Object.assign({}, state, {pspId: action.pspId});
    }

    function setPspSubmitterId(state, action) {
        return Object.assign({}, state, {pspSubmitterId: action.pspSubmitterId});
    }

    function setPspName(state, action) {
        return Object.assign({}, state, {pspName: action.pspName});
    }

    function setPspSENumber(state, action) {
        return Object.assign({}, state, {pspSENumber: action.pspSENumber});
    }

    function setPspSPC(state, action) {
        return Object.assign({}, state, {pspSPC: action.pspSPC});
    }

    function setPspCountryCode(state, action) {
        return Object.assign({}, state, {pspCountryCode: action.pspCountryCode});
    }

    function setPspSbmnSubmitterId(state, action) {
        return Object.assign({}, state, {pspSbmnSubmitterId: action.pspSbmnSubmitterId});
    }

    function setFiids(state, action) {
        return Object.assign({}, state, {fiids: action.fiids});
    }

    function setNotes(state, action) {
        return Object.assign({}, state, {notes: action.notes});
    }

    function setMdaCredit(state, action) {
        return Object.assign({}, state, {mdaCredit: action.mdaCredit});
    }

    function setMdaDebit(state, action) {
        return Object.assign({}, state, {mdaDebit: action.mdaDebit});
    }

    return {
        [Symbol.for(SET_PSP_ID)]: setPspId,
        [Symbol.for(SET_PSP_SUBMITTER_ID)]: setPspSubmitterId,
        [Symbol.for(SET_PSP_NAME)]: setPspName,
        [Symbol.for(SET_PSP_SE_NUMBER)]: setPspSENumber,
        [Symbol.for(SET_PSP_SPC)]: setPspSPC,
        [Symbol.for(SET_PSP_COUNTRY_CODE)]: setPspCountryCode,
        [Symbol.for(SET_PSP_SBMN_SUBMITTER_ID)]: setPspSbmnSubmitterId,
        [Symbol.for(SET_FIIDS)]: setFiids,
        [Symbol.for(SET_NOTES)]: setNotes,
        [Symbol.for(SET_MDA_CREDIT)]: setMdaCredit,
        [Symbol.for(SET_MDA_DEBIT)]: setMdaDebit,
    }
}());

const AmxPspStore = class extends ReduceStore {
    constructor() {
        super(AppDispatcher);
        this.getInitialState = this.getInitialState.bind(this);
    }

    getInitialState() {
        return {
            pspId: undefined,
            pspSubmitterId: undefined,
            pspName: undefined,
            pspSENumber: undefined,
            pspSPC: undefined,
            pspCountryCode: undefined,
            pspSbmnSubmitterId: undefined,
            fiids: undefined,
            notes: undefined,
            mdaCredit: 0,
            mdaDebit: 0
        };
    }

    reduce(state, action) {
        if (stateCallbacks[Symbol.for(action.type)]) {
            return stateCallbacks[Symbol.for(action.type)](state, action);
        } else {
            return state;
        }
    }
};

export default new AmxPspStore();