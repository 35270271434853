import React, {Component} from 'react';
import {Alert, Button, ButtonGroup, ButtonToolbar, Card, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {saveUserReader, updateUserReader, uploadUserReaderFile} from "../../api/CoreHandlerApi";
import ReactFileReader from "react-file-reader";

let modalResponseColor = '';
let updateAction = false;
let loadingText = 'Cargando...';
let successFromFile = [];
let errorFromFile = [];
let warningFromFile = [];
let RESPONSE_CODE = {
    SUCCESS: "0",
    ERROR: "1",
    WARNING: "2"
}

export class UserReaderPanel extends Component {
    constructor(props) {
        super(props);
        this.saveUserReader = this.saveUserReader.bind(this);
        this.handleFiles = this.handleFiles.bind(this);
    }

    saveUserReader() {
        this.props.utils.setSpinner(true);
        saveUserReader(this.props.userReaderState)
            .then(resp => this.handleSuccess(resp))
            .catch(err => this.handleError(err.toString()));
    }

    updateUserReader() {
        this.props.utils.showErrorDialog(undefined)
        this.props.utils.setSpinner(true);
        updateUserReader(this.props.userReaderState)
            .then(resp => this.handleSuccess(resp))
            .catch(err => this.handleError(err.toString()));
    }

    handleSuccess(resp) {
        updateAction = false;
        this.props.utils.setSpinner(false);
        if (resp.code === RESPONSE_CODE.SUCCESS) {
            this.props.userReader.setUser('');
            this.props.userReader.setReader('');

            modalResponseColor = 'success';
            this.props.utils.showErrorDialog('Lector asignado correctamente');
        } else if (resp.code === RESPONSE_CODE.WARNING) {
            modalResponseColor = 'warning';

            let msg;
            if (resp.message.user === this.props.userReaderState.user) {
                msg = `El lector (${resp.message.reader}) ya se asignó al usuario (${resp.message.user})`;
            } else {
                updateAction = true;
                msg = `El lector (${resp.message.reader}) ya tiene usuario asignado (${resp.message.user}), ¿Desea actualizar el lector al usuario (${this.props.userReaderState.user})`;
            }

            this.props.utils.showErrorDialog(msg);
        } else {
            this.handleError(resp.message);
        }
    }

    handleError(err) {
        console.error(err);
        updateAction = false;
        this.props.utils.setSpinner(false);

        modalResponseColor = 'danger';
        this.props.utils.showErrorDialog(err);
    }

    handleFiles(files) {
        loadingText = 'Leyendo archivo...';
        successFromFile = [];
        errorFromFile = [];
        warningFromFile = [];
        this.props.utils.setSpinner(true);

        let reader = new FileReader();
        this.readData(reader)
            .then(() => this.searchDataFromFile(reader.result.split('\n'), 0, files[0]))
            .catch(e => this.handleFilesError(e));
        reader.readAsText(files[0]);
    }

    async readData(reader) {
        return new Promise(resolve => {
            reader.onload = () => {
                let arrayData = reader.result.split('\n');
                let userReaderFile = [];
                arrayData.forEach(data => userReaderFile.push(data ? data : null));
                resolve(userReaderFile);
            }
        });
    }

    searchDataFromFile(data, index, file) {
        let userReaderObj = {
            user: parseInt(data[index].split(',')[0]),
            reader: data[index].split(',')[1].replace(/[\r]+/g, '')
        }

        loadingText = `Guardando ${index+1} de ${data.length}`;
        this.props.utils.setSpinner(true);

        saveUserReader(userReaderObj)
            .then(resp => this.handleSaveUserReaderFile(resp, data, index, file))
            .catch(err => this.handleSaveUserReaderFileError(err.toString(), data, index, file));
    }

    handleSaveUserReaderFile(resp, data, index, file) {
        let user = data[index].split(',')[0];
        let reader = data[index].split(',')[1];

        if (resp.code === RESPONSE_CODE.SUCCESS) {
            successFromFile.push(reader);
        } else if (resp.code === RESPONSE_CODE.WARNING) {
            warningFromFile.push(this.getWarningMsg(user, resp));
        } else if (resp.code === RESPONSE_CODE.ERROR) {
            errorFromFile.push(reader);
        }

        if (data.length === index + 1) {
            this.finishFileReader(file);
        } else {
            this.searchDataFromFile(data, index+1, file);
        }
    }

    getWarningMsg(user, resp) {
        return resp.message.user.toString() === user ?
            `El lector (${resp.message.reader}) ya se asignó al usuario (${resp.message.user}).\n` :
            `El lector (${resp.message.reader}) esta asignado al usuario (${resp.message.user}). Si desea actualizar el registro ingrese los datos en el formulario para actualizar.\n`;
    }

    handleFilesError(e) {
        console.error(e);
        this.props.utils.setSpinner(false);
    }

    handleSaveUserReaderFileError(e, data, index, file) {
        console.error(e);
        console.log('error', data[index].split(',')[1]);
        errorFromFile.push(data[index].split(',')[1]);
        if (data.length !== index + 1) {
            this.finishFileReader(file);
        }
    }

    finishFileReader(file) {
        this.props.utils.setSpinner(false);
        modalResponseColor = 'info';

        let dialogMsg = '';
        if (errorFromFile.length > 0) {
            dialogMsg += `Los lectores (${errorFromFile.toString()}) no se han podido almacenar por un error interno. Intente guardarlos de nuevo.\n`;
        }

        if (warningFromFile.length > 0) {
            dialogMsg += warningFromFile.toString().replace(',', '');
        }

        if (successFromFile.length > 0) {
            dialogMsg += `Los lectores (${successFromFile.toString()}) se han asignado correctamente.\n`;
            uploadUserReaderFile(file)
                .then(response => console.log(response))
                .catch(errorFromFile => console.error(errorFromFile));
        }

        this.props.utils.showErrorDialog(dialogMsg.replace(',', ''));
    }

    render() {
        let formValidation = !!this.props.userReaderState.user && !!this.props.userReaderState.reader;

        return(<>
            <Modal
                show={this.props.utilsState.spinner}
                onHide={() => !this.props.utilsState.spinner}
                backdrop='static'
                keyboard={false}
            >
                <Modal.Body>
                    {
                        loadingText ?
                            <b> {loadingText} </b> :
                            <b> Cargando... </b>
                    }
                    <Spinner animation="grow" size="sm" role="status">
                        <span className="sr-only"/>
                    </Spinner>
                </Modal.Body>
            </Modal>

            <Modal
                show={!!this.props.utilsState.errorDialog}
                onHide={() => this.props.utils.showErrorDialog(undefined)}
                backdrop='static'
                keyboard={false}>
                <Modal.Body>
                    <Alert variant={modalResponseColor}>
                        <b><pre>{this.props.utilsState.errorDialog}</pre></b>
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    {
                        updateAction ?
                            <>
                                <Button variant="danger" onClick={() => this.props.utils.showErrorDialog(undefined)}>
                                    Cancelar
                                </Button>
                                <Button variant="primary" onClick={() => this.updateUserReader()}>
                                    Actualizar
                                </Button>
                            </>:
                            <Button variant="primary" onClick={() => this.props.utils.showErrorDialog(undefined)}>
                                Aceptar
                            </Button>
                    }
                </Modal.Footer>
            </Modal>

            <Card className="bg-info text-white">
                <Card.Header>
                    <h4><b>Asignación de lectores</b></h4>
                </Card.Header>
            </Card>

            <Card className="bg-danger text-white">
                <Card.Header>
                    <h4><b>*NOTA: Los lectores ULTRA no pueden ser asigandos a ningún usuario</b></h4>
                </Card.Header>
            </Card>

            <Alert>
                <Form>
                    <Form.Group as={Row}>
                        <Form.Label column sm="4">Usuario</Form.Label>
                        <Col sm="6">
                            <Form.Control type="number" onChange={ev => this.props.userReader.setUser(parseInt(ev.target.value))} value={this.props.userReaderState.user} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm="4">Lector</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" onChange={ev => this.props.userReader.setReader(ev.target.value)} value={this.props.userReaderState.reader} />
                        </Col>
                    </Form.Group>

                    <ButtonToolbar>
                        <ButtonGroup className="mr-2">
                            <Button variant='warning' disabled={!formValidation} onClick={() => this.saveUserReader()}>
                                Guardar
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup className="mr-2">
                            <ReactFileReader fileTypes={".csv"} handleFiles={this.handleFiles}>
                                <Button variant='info'>Cargar CSV</Button>
                            </ReactFileReader>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Form>
            </Alert>
        </>);
    }
}