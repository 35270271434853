export const ADMIN_GROUP_NAME = "Admins";
export const REFUND_GROUP_NAME = "Refunds";
export const BALANCER_GROUP_NAME = "Balancer";
export const AUDIT_GROUP_NAME = "Audit";
export const AMEX_FIID_GROUP_NAME = "AmexFiid";
export const READER_ASSIGNMENT_GROUP_NAME = "ReaderAssignment";
export const FEE_GROUP_NAME = "FeeRetention";
export const B24_REFUNDS_GROUP_NAME = "B24Refunds";
export const AMEX_GSMF_GROUP_NAME = "AmexGsmf";
export const JWT_MAIL_ATTRIBUTE = "email";
export const JWT_GROUP_ATTRIBUTE = "cognito:groups";
// export const BALANCER_STATUS_UPDATE_INTERVAL = 1000 * 60 * 1; // One minutes
export const ACTION_TYPE_SWITCH_BALANCER_ACTIVATION = "SWITCH_BALANCER_ACTIVATION";
export const ACTION_TYPE_SWITCH_BASE_ENDPOINT = "SWITCH_BASE_ENDPOINT";
export const ACTION_STATUS_CONFIRMED = "CONFIRMED";
export const ACTION_STATUS_SUCCESS = "SUCCESS";

export function configureLoggedUser(loggedUser) {
    if (loggedUser) {
        let current = {
            isAdmin: false,
            isBalancer: false,
            isAudit: false,
            isRefund: false,
            isAmexFiid: false,
            isFeeRetention: false,
            isReaderAssignment: false,
            isB24Refund: false,
            isAmexGsmf: false,
            email: ""
        };

        if (loggedUser && loggedUser.signInUserSession && loggedUser.signInUserSession.idToken) {
            current["email"] = loggedUser.signInUserSession.idToken.payload[JWT_MAIL_ATTRIBUTE] || "";

            let groups = loggedUser.signInUserSession.idToken.payload[JWT_GROUP_ATTRIBUTE];

            if (groups && Array.isArray(groups)) {
                if (groups.includes(ADMIN_GROUP_NAME)) {
                    current.isAdmin = true;
                }

                if (groups.includes(BALANCER_GROUP_NAME)) {
                    current.isBalancer = true;
                }

                if (groups.includes(AUDIT_GROUP_NAME)) {
                    current.isAudit = true;
                }

                if (groups.includes(REFUND_GROUP_NAME)) {
                    current.isRefund = true;
                }

                if (groups.includes(AMEX_FIID_GROUP_NAME)) {
                    current.isAmexFiid = true;
                }

                if (groups.includes(FEE_GROUP_NAME)) {
                    current.isFeeRetention = true;
                }

                if (groups.includes(READER_ASSIGNMENT_GROUP_NAME)) {
                    current.isReaderAssignment = true;
                }

                if (groups.includes(B24_REFUNDS_GROUP_NAME)) {
                    current.isB24Refund = true;
                }

                if (groups.includes(AMEX_GSMF_GROUP_NAME)) {
                    current.isAmexGsmf = true;
                }
            }
        }

        return current;
    } else {
        return loggedUser;
    }
}