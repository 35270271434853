import React, {Component} from 'react';
import {
    Alert,
    Button,
    ButtonGroup,
    ButtonToolbar,
    Card,
    Col,
    Form,
    FormControl,
    InputGroup, Modal,
    OverlayTrigger,
    Pagination,
    Row,
    Spinner,
    Table,
    Tooltip
} from 'react-bootstrap';
import {CalendarIcon, TrashIcon} from '@primer/octicons-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ReactJson from 'react-json-view';
import {getAuditsLogs} from "../../api/CoreHandlerApi";

const CustomInputDatepicker = ({onClick}) => (
    <Button variant="outline-secondary" onClick={onClick}><CalendarIcon/></Button>
);
const PAGINATION_LIMIT = 10;
const FORMAT_DATEPICKER = 'dd-MM-yyyy';
const ACTIONS = {
    FIRST: 'First',
    PREV: 'Previous',
    NEXT: 'Next',
    LAST: 'Last'
};
const JSON = {
    RESPONSE: 'response',
    CLAIMS: 'claims'
};
const ALERT = {
    EMPTY_START_DATE: 'La fecha inicial está vacía',
    EMPTY_END_DATE: 'La fecha final está vacía',
    START_DATE_CANT_GREATER_THAN_END_DATE: 'La fecha inicial no puede ser posterior a la fecha final'
};

export class AuditPanel extends Component {

    constructor(props) {
        super(props);
        this.getAudits = this.getAudits.bind(this);
        this.dataTable = this.dataTable.bind(this);
        this.getPagination = this.getPagination.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.setFilters = this.setFilters.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.validateFilters = this.validateFilters.bind(this);
        this.formatDate = this.formatDate.bind(this);
    }

    componentDidMount() {
        this.props.utils.setActivePage(1);
        this.getAudits(null, null, null);
    }

    validateFilters() {
        if (this.props.auditState.startDate && !this.props.auditState.endDate) {
            return ALERT.EMPTY_END_DATE;
        }

        if (!this.props.auditState.startDate && this.props.auditState.endDate) {
            return ALERT.EMPTY_START_DATE;
        }

        if (this.props.auditState.startDate && this.props.auditState.endDate && this.props.auditState.startDate.getTime() > this.props.auditState.endDate.getTime()) {
            return ALERT.START_DATE_CANT_GREATER_THAN_END_DATE;
        }

        return '';
    }

    setFilters() {
        if (this.validateFilters() === '' && !this.props.utilsState.spinner) {
            let startDate = this.props.auditState.startDate;
            let endDate = this.props.auditState.endDate;
            let username = this.props.auditState.username;
            this.getAudits(startDate, endDate, username);
        }
    }

    clearFilters() {
        this.props.audit.setUsername('');
        this.props.audit.setStartDate('');
        this.props.audit.setEndDate('');
    }

    getAudits(startDate, endDate, username) {
        this.props.utils.setSpinner(true);
        getAuditsLogs(startDate, endDate, username)
            .then(audits => this.setAudits(audits))
            .catch(e => this.showError(e));
    }

    setAudits(audits) {
        this.props.audit.setAudits(audits);
        this.props.utils.setSpinner(false);
    }

    showError(e) {
        console.error(e);
        this.props.utils.setSpinner(false);
    }

    dataTable() {
        let dataTable = [];
        let audits = this.props.auditState.audits;
        let activePage = this.props.utilsState.activePage;
        if (audits) {
            audits.Items.sort( (a,b) => new Date(a.date) > new Date(b.date)? -1: 1);

            let initItem = (activePage - 1) * PAGINATION_LIMIT;
            let lastItem = (initItem + PAGINATION_LIMIT) > audits.Count ? audits.Count : (initItem + PAGINATION_LIMIT);
            for (let index = initItem; index < lastItem; index++) {
                dataTable.push(
                    <tr key={index}>
                        <td>{this.formatDateWithHour(new Date(audits.Items[index].date))}</td>
                        <td>{audits.Items[index].method}</td>
                        <td>{audits.Items[index].serviceName}</td>
                        {audits.Items[index].claims && audits.Items[index].claims.email? <td>{audits.Items[index].claims.email}</td>: <td></td>}
                        <td><ReactJson src={audits.Items[index].response} name={JSON.RESPONSE} collapsed={true}/></td>
                        <td><ReactJson src={audits.Items[index].claims} name={JSON.CLAIMS} collapsed={true}/></td>
                    </tr>
                );
            }
        }

        return dataTable;
    }

    getPagination() {
        let pagination = [];
        let audits = this.props.auditState.audits;
        let activePage = this.props.utilsState.activePage;
        if (audits && audits.length >= PAGINATION_LIMIT) {
            let totalPage = this.getTotalPages();
            pagination.push(
                <Pagination key={1} className='Pagination'>
                    <Pagination.First onClick={this.goToPage}/>
                    <Pagination.Prev onClick={this.goToPage}/>
                    <Pagination.Item active={true}>
                        {activePage} de {totalPage}
                    </Pagination.Item>
                    <Pagination.Next onClick={this.goToPage}/>
                    <Pagination.Last onClick={this.goToPage}/>
                </Pagination>
            );
        }

        return pagination;
    }

    getTotalPages() {
        let audits = this.props.auditState.audits;
        if (audits === undefined || audits.Count <= PAGINATION_LIMIT) {
            return 1;
        }

        let pagNumber = audits.Count / PAGINATION_LIMIT;
        return audits.Count % PAGINATION_LIMIT === 0 ? pagNumber : Math.floor(pagNumber) + 1;
    }

    goToPage(event) {
        let action = event.currentTarget.getElementsByClassName("sr-only")[0].textContent;
        switch (action) {
            case ACTIONS.FIRST:
                this.props.utils.setActivePage(1);
                break;
            case ACTIONS.PREV:
                let prev = this.props.utilsState.activePage <= 1 ? 1 : (this.props.utilsState.activePage - 1);
                this.props.utils.setActivePage(prev);
                break;
            case ACTIONS.NEXT:
                let totalPages = this.getTotalPages();
                let next = this.props.utilsState.activePage >= totalPages ? totalPages : (this.props.utilsState.activePage + 1);
                this.props.utils.setActivePage(next);
                break;
            case ACTIONS.LAST:
                this.props.utils.setActivePage(this.getTotalPages());
                break;
            default:
                break;
        }
    }

    formatDate(date, text) {
        if (date) {
            let day = date.getDate();
            day = day < 10 ? '0' + day : day;
            let month = date.getMonth() + 1;
            month = month < 10 ? '0' + month : month;
            let year = date.getFullYear();

            return [day, month, year].join('-');
        }

        return 'Fecha ' + text;
    }

    formatDateWithHour(date) {
        if(date) {
            let minutes = date.getMinutes();
            minutes = minutes < 10? '0' + minutes: minutes;
            let hour = date.getHours();
            hour = hour < 10? '0' + hour: hour;
            let day = date.getDate();
            day = day < 10? '0' + day: day;
            let month = date.getMonth() + 1;
            month = month < 10? '0' + month: month;
            let year = date.getFullYear();

            return [[day, month, year].join('-'), [hour, minutes].join(':')].join(' ');
        }
    }

    render() {
        let disabled = this.validateFilters();

        return (
            <>
                <Card className="bg-info text-white">
                    <Card.Header>
                        <h4><b>Logs de auditoría</b></h4>
                    </Card.Header>
                </Card>

                <Modal
                    show={this.props.utilsState.spinner}
                    onHide={!this.props.utilsState.spinner}
                    backdrop='static'
                    keyboard={false}
                >
                    <Modal.Body>
                        <div>
                            <b> Cargando... </b>
                            <Spinner animation="grow" size="sm" role="status">
                                <span className="sr-only"/>
                            </Spinner>
                        </div>
                    </Modal.Body>
                </Modal>

                {
                    disabled !== '' ?
                        <Alert variant='danger'>
                            <b>{disabled}</b>
                        </Alert> :
                        null
                }

                <Card>
                    <Card.Body>

                        <Form>
                            <Form.Group as={Row}>
                                <Form.Label column sm="2">Usuario</Form.Label>
                                <Col sm="6">
                                    <Form.Control type="text"
                                                  onChange={val => this.props.audit.setUsername(val.target.value)}
                                                  value={this.props.auditState.username}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Form.Label column sm="2">Fecha</Form.Label>
                                <Col sm="6">
                                    <InputGroup>
                                        <FormControl placeholder={this.formatDate(this.props.auditState.startDate, 'inicial')}
                                                     disabled={true}/>
                                        <InputGroup.Append>
                                            <DatePicker selected={this.props.auditState.startDate}
                                                        onChange={date => this.props.audit.setStartDate(date)}
                                                        dateFormat={FORMAT_DATEPICKER}
                                                        customInput={<CustomInputDatepicker/>}
                                            />
                                        </InputGroup.Append>

                                        <FormControl placeholder={this.formatDate(this.props.auditState.endDate, 'final')}
                                                     disabled={true}/>
                                        <InputGroup.Append>
                                            <DatePicker selected={this.props.auditState.endDate}
                                                        onChange={date => this.props.audit.setEndDate(date)}
                                                        dateFormat={FORMAT_DATEPICKER}
                                                        customInput={<CustomInputDatepicker/>}
                                            />
                                        </InputGroup.Append>

                                    </InputGroup>
                                </Col>
                            </Form.Group>

                            {/*<Form.Group as={Row}>
                                <Form.Label column sm="2">Fecha Final</Form.Label>
                                <Col sm="4">
                                    <InputGroup>
                                        <FormControl placeholder={this.formatDate(this.props.auditState.endDate)}
                                                     disabled={true}/>
                                        <InputGroup.Append>
                                            <DatePicker selected={this.props.auditState.endDate}
                                                        onChange={date => this.props.audit.setEndDate(date)}
                                                        dateFormat={FORMAT_DATEPICKER}
                                                        customInput={<CustomInputDatepicker/>}
                                            />
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Form.Group>*/}

                            <ButtonToolbar>
                                <ButtonGroup className="mr-2">
                                    <OverlayTrigger overlay={<Tooltip>Limpiar filtros</Tooltip>}>
                                        <Button variant='primary' onClick={this.clearFilters}><TrashIcon/></Button>
                                    </OverlayTrigger>
                                </ButtonGroup>
                                <ButtonGroup className="mr-2">
                                    <Button variant={disabled === '' ? 'warning' : 'danger'} onClick={this.setFilters}
                                            disabled={disabled !== ''}>
                                        Filtrar
                                    </Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </Form>
                    </Card.Body>
                </Card>

                <Table striped bordered hover>
                    <thead className="thead-dark">
                    <tr>
                        <th>Fecha</th>
                        <th>Método</th>
                        <th>Servicio</th>
                        <th>Email</th>
                        <th>Response</th>
                        <th>Claims</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.dataTable()}
                    </tbody>
                </Table>
                {this.getPagination()}
            </>
        );
    }
}