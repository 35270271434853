import AppDispatcher from "../dispatchers/AppDispatcher";

export const SET_PSP_ID = 'SET_PSP_ID';
export const SET_PSP_SUBMITTER_ID = 'SET_PSP_SUBMITTER_ID';
export const SET_PSP_NAME = 'SET_PSP_NAME';
export const SET_PSP_SE_NUMBER = 'SET_PSP_SE_NUMBER';
export const SET_PSP_SPC = 'SET_PSP_SPC';
export const SET_PSP_COUNTRY_CODE = 'SET_PSP_COUNTRY_CODE';
export const SET_PSP_SBMN_SUBMITTER_ID = 'SET_PSP_SBMN_SUBMITTER_ID';
export const SET_FIIDS = 'SET_FIIDS';
export const SET_NOTES = 'SET_NOTES';
export const SET_MDA_CREDIT = 'SET_MDA_CREDIT';
export const SET_MDA_DEBIT = 'SET_MDA_DEBIT';

export function setPspId(pspId) {
    AppDispatcher.dispatch({
        type: SET_PSP_ID,
        pspId: pspId
    });
}

export function setPspSubmitterId(pspSubmitterId) {
    AppDispatcher.dispatch({
        type: SET_PSP_SUBMITTER_ID,
        pspSubmitterId: pspSubmitterId
    });
}

export function setPspName(pspName) {
    AppDispatcher.dispatch({
        type: SET_PSP_NAME,
        pspName: pspName
    });
}

export function setPspSENumber(pspSENumber) {
    AppDispatcher.dispatch({
        type: SET_PSP_SE_NUMBER,
        pspSENumber: pspSENumber
    });
}

export function setPspSPC(pspSPC) {
    AppDispatcher.dispatch({
        type: SET_PSP_SPC,
        pspSPC: pspSPC
    });
}

export function setPspCountryCode(pspCountryCode) {
    AppDispatcher.dispatch({
        type: SET_PSP_COUNTRY_CODE,
        pspCountryCode: pspCountryCode
    });
}

export function setPspSbmnSubmitterId(pspSbmnSubmitterId) {
    AppDispatcher.dispatch({
        type: SET_PSP_SBMN_SUBMITTER_ID,
        pspSbmnSubmitterId: pspSbmnSubmitterId
    });
}

export function setFiids(fiids) {
    AppDispatcher.dispatch({
        type: SET_FIIDS,
        fiids: fiids
    });
}

export function setNotes(notes) {
    AppDispatcher.dispatch({
        type: SET_NOTES,
        notes: notes
    })
}

export function setMdaCredit(mdaCredit) {
    AppDispatcher.dispatch({
        type: SET_MDA_CREDIT,
        mdaCredit: mdaCredit
    })
}

export function setMdaDebit(mdaDebit) {
    AppDispatcher.dispatch({
        type: SET_MDA_DEBIT,
        mdaDebit: mdaDebit
    })
}