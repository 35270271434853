import React, {Component} from 'react';
import {
    Card,
    Table,
    Form,
    Row,
    Col,
    ButtonGroup,
    Button,
    ButtonToolbar,
    Pagination,
    Alert,
    Spinner,
    Modal,
    Accordion,
    Tabs,
    Tab
} from 'react-bootstrap';
import {forceRefund, partialRefund, getRefunds, remoteRefund, feeRetention} from "../../api/CoreHandlerApi";
import ReactFileReader from 'react-file-reader';

const PAGINATION_LIMIT = 10;
const ACTIONS = {
    FIRST: 'First',
    PREV: 'Previous',
    NEXT: 'Next',
    LAST: 'Last'
};
const TAB = {
    FORZADAS: 1,
    PARCIALES: 2,
    MASIVAS: 3,
    PAGO_DISTANCIA: 4,
    RETENCIONES: 5
};
const ENTER_KEY = 13;
const AMEX_CARD_TYPE = 'American Express';
let headingText = '';
let bodyText = '';
let buttonsDialog = null;
let partialRefundId = null;
let tabActive = TAB.FORZADAS;
let totalAmountTx = 0;
let nonExistentTransactions = [];
let refundedTransactions = [];
let amexRefunds = [];
let loadingText = 'Cargando...';
const CONFIRM_METHOD = {
    WITH_CONFIRMATION: 'withConfirmation',
    WITHOUT_CONFIRMATION: 'withoutConfirmation',
    RETENTION: 'retention'
}
const REFUND_COMPLETED_STATUS = 1;
const REFUND_TYPE = {
    SALE: 'venta',
    REFUND: 'devolucion'
}
const COLOR = {
    SUCCESS: 'success',
    ERROR: 'danger',
    INFO: 'info'
}

export class RefundsPanel extends Component {

    constructor(props) {
        super(props);
        this.getRefunds = this.getRefunds.bind(this);
        this.getRefundsFromFile = this.getRefundsFromFile.bind(this);
        this.pagination = this.pagination.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.validationsIds = this.validationsIds.bind(this);
        this.searchTransactions = this.searchTransactions.bind(this);
        this.handleFiles = this.handleFiles.bind(this);
        this.setIds = this.setIds.bind(this);
        this.forceRefund = this.forceRefund.bind(this);
        this.closeConfirmDialog = this.closeConfirmDialog.bind(this);
        this.onChangeTabs = this.onChangeTabs.bind(this);
        this.forceAll = this.forceAll.bind(this);
    }

    componentDidMount() {
        this.props.utils.setActivePage(1);
        this.props.refunds.setTxHistory(undefined);
        this.props.refunds.showAlertError(undefined);
        this.props.refunds.setTransactionIds(undefined);
        this.props.refunds.setPartialTxHistory(undefined);
        this.props.refunds.setPartialTransactionIds(undefined);
        headingText = '';
        bodyText = '';
        buttonsDialog = null;
        partialRefundId = null;
    }

    componentWillUnmount() {
        this.props.refunds.setTransactionIds(undefined);
        this.props.refunds.setPartialTransactionIds(undefined);
    }

    getRefunds(transactionIds, searchRefunds) {
        if (transactionIds) {
            let refundsArray = [];
            refundedTransactions = [];
            nonExistentTransactions = [];
            amexRefunds = [];
            this.callRefund(transactionIds, 0, refundsArray, false, searchRefunds);
        }
    }

    getRefundsFromFile(transactionIds) {
        if (transactionIds) {
            let refundsArray = [];
            refundedTransactions = [];
            nonExistentTransactions = [];
            amexRefunds = [];
            this.callRefund(transactionIds, 0, refundsArray, true, false);
        }
    }

    callRefund(transactionIds, index, refundsArray, isFromFile, searchRefunds) {
        loadingText = `Buscando transacciones ${index + 1} de ${transactionIds.length}`;
        this.props.utils.setSpinner(true);
        getRefunds(transactionIds[index],  searchRefunds ? REFUND_COMPLETED_STATUS: undefined)
            .then(refunds => this.handleRefundsResponse(refunds, transactionIds, index,  refundsArray, isFromFile))
            .catch(e => this.handleRefundsError(e));
    }

    handleRefundsResponse(refunds, transactionIds, index, refundsArray, isFromFile) {
        if (refunds && refunds.length > 0) {
            refunds.forEach(refund => refund.saleId = parseInt(transactionIds[index]));

            refundsArray = refundsArray.concat(refunds);

            if (tabActive === TAB.FORZADAS){
                this.verifySale(refunds, transactionIds[index]);
                this.props.refunds.setTxHistory(refundsArray);
            } else if (tabActive === TAB.PARCIALES) {
                refundsArray = this.removeAMEXTx(refundsArray);
                this.props.refunds.setPartialTxHistory(refundsArray);
            } else if (tabActive === TAB.MASIVAS) {
                this.verifySale(refunds, transactionIds[index]);
                this.props.refunds.setFileTxHistory(refundsArray);
            } else if (tabActive === TAB.PAGO_DISTANCIA) {
                this.verifySale(refunds,  transactionIds[index]);
                this.props.refunds.setRemoteTxHistory(refundsArray);
            } else if (tabActive === TAB.RETENCIONES) {
                this.props.refunds.setRetentionsTxHistory(refundsArray);
            }

            if (index + 1 === transactionIds.length) {
                this.setErrorMsg();
                this.props.utils.setSpinner(false);
                loadingText = undefined;
            } else {
                this.callRefund(transactionIds, index + 1, refundsArray, isFromFile, false);
            }
        } else {
            if (isFromFile) {
                nonExistentTransactions.push(transactionIds[index]);
                nonExistentTransactions.sort();
                this.callRefund(transactionIds, index + 1, refundsArray, isFromFile, false);
            } else {
                this.checkIfTransactionExist(transactionIds, index, refundsArray);
            }
        }
    }

    handleRefundsError(e) {
        console.error(e);
        this.props.utils.setSpinner(false);
        this.setErrorMsg();
        loadingText = undefined;
    }

    checkIfTransactionExist(transactionIds, index, refundsArray) {
        getRefunds(transactionIds[index], REFUND_COMPLETED_STATUS)
            .then(ref => this.handleRefundsCompleted(ref, transactionIds, index, refundsArray));
    }

    handleRefundsCompleted(ref, transactionIds, index, refundsArray) {
        if(ref.length > 0) {
            refundedTransactions.push(transactionIds[index]);
            refundedTransactions.sort();
        } else {
            nonExistentTransactions.push(transactionIds[index]);
            nonExistentTransactions.sort();
        }

        if (index + 1 === transactionIds.length) {
            this.setErrorMsg();
            this.props.utils.setSpinner(false);
            loadingText = undefined;
        } else {
            this.callRefund(transactionIds, index + 1, refundsArray, false, false);
        }
    }

    setErrorMsg() {
        let msg = '';

        if (refundedTransactions.length > 0) {
            msg = `Las transacciones (${refundedTransactions}) ya fueron devueltas previamente`.replace(/,/g, ', ');
        }

        if (nonExistentTransactions.length > 0) {
            msg =
                tabActive === TAB.RETENCIONES ?
                    `La transacción (${nonExistentTransactions}) no existe o no tiene una devolución`.replace(/,/g, ', ') :
                    `Las transacciones (${nonExistentTransactions}) no existen`.replace(/,/g, ', ');
        }

        if (amexRefunds.length > 0) {
            msg = `Las transacciones (${amexRefunds}) al ser de American Express no se pueden procesar parcialmente`.replace(/,/g, ', ');
        }

        if (msg.length > 0) {
            this.props.refunds.showAlertError(
                (this.props.refundState.alertError ? this.props.refundState.alertError : '')
                + `${msg}. `);
        }
    }

    verifySale(refunds, idTransaction) {
        let hasSale = false;
        refunds.forEach(refund => {
            if(refund.txId === parseFloat(idTransaction)) {
                hasSale = true;
            }
        });

        if(!hasSale) {
            refundedTransactions.push(idTransaction);
        }
    }

    removeAMEXTx(refundsArray) {
        amexRefunds = [];

        refundsArray.forEach((refund, index) => {
            if (refund.cardType === AMEX_CARD_TYPE ) {
                amexRefunds.push(refund.txId);
                refundsArray.splice(index,  1);
            }
        });

        return refundsArray;
    }

    showConfirmationDialogForce(tx) {
        let heading = 'Confirmación para forzar la devolución';
        let body = `¿Está seguro de forzar la devolución para la transacción con el ID ${tx.txId}?`;
        this.showConfirmationDialog(heading, body, CONFIRM_METHOD.WITH_CONFIRMATION, COLOR.INFO, undefined, tx);
    }

    showConfirmationDialogPartialsRefund(tx) {
        partialRefundId = tx.txId;
        headingText = 'Confirmación para realizar devolución parcial';
        let body = `Ingrese el monto a devolver para la transacción con el ID ${partialRefundId}, el monto a devolver debe ser menor o igual al monto de la transacción + la propina ($${this.formatCash(totalAmountTx)})`;

        bodyText = (
            <>
                <Alert variant={COLOR.SUCCESS}>
                    <b>{body}</b>
                </Alert>
            </>
        );

        let amountPartialRefund = 0;
        buttonsDialog = (
            <>
                <Form.Control type="number"
                              onKeyPress={event => {
                                  if (event.which === ENTER_KEY) {
                                      event.preventDefault();
                                  }
                              }}
                              onChange={val => amountPartialRefund = val.target.value}
                />
                <Button variant="secondary" onClick={this.closeConfirmDialog}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={() => this.partialRefund(tx, amountPartialRefund)}>
                    Confirmar
                </Button>
            </>
        );

        this.props.refunds.showConfirmDialog(true);
    }

    showConfirmationDialogRemoteRefund(tx) {
        let heading = 'Confirmación para realizar la devolución';
        let body = `¿Está seguro de realizar la devolución para la transacción con el ID ${tx.txId}?`;
        this.showConfirmationDialog(heading, body, CONFIRM_METHOD.WITH_CONFIRMATION, COLOR.INFO, undefined, tx);
    }

    showConfirmationDialogRetention(tx) {
        let idTxRetention = tx.txType === REFUND_TYPE.REFUND && tabActive !== TAB.RETENCIONES ? tx.saleId : tx.txId;

        if (!tx.comisionBase) {
            let heading = 'Confirmación para realizar la retención';
            let body = `¿Está seguro de realizar la retención para la transacción con el ID ${idTxRetention}?`;
            this.showConfirmationDialog(heading, body, CONFIRM_METHOD.RETENTION, COLOR.INFO, undefined, tx);
        } else {
            let heading = 'La transacción ya ha sido retenida';
            let body = `La transacción con el ID ${idTxRetention} ya se encuentra retenida con el total de $${tx.comisionBase + tx.comisionQ6}`;
            this.showConfirmationDialog(heading, body, CONFIRM_METHOD.WITHOUT_CONFIRMATION, COLOR.ERROR, undefined, tx);
        }
    }

    showConfirmationDialog(heading, body, methodConfirm, color, details, tx) {
        headingText = heading;
        bodyText = (<>
            <Alert variant={color}>
                <b>{body}</b>
            </Alert>
            {
                details ?
                    <Alert variant='light'>
                        {details}
                    </Alert> : null
            }
        </>);
        buttonsDialog = this.getButtonsDialog(methodConfirm, tx);
        this.props.refunds.showConfirmDialog(true);
    }

    showConfirmationDialogFile(details, tx) {
        headingText = 'Resultado de las devoluciones masivas';

        let dataBody = [];
        details.sort((a, b) => a.id > b.id ? 1 : -1);
        details.forEach(data => {
            dataBody.push(<>
                <tr>
                    <td>{data.id}</td>
                    <td>{data.body}</td>
                </tr>
            </>);
        });

        bodyText = (<>
            <Table striped bordered hover>
                <thead className="thead-dark">
                <tr>
                    <th>Transacción</th>
                    <th>Resultado</th>
                </tr>
                </thead>
                <tbody>
                {dataBody}
                </tbody>
            </Table>
        </>);
        buttonsDialog = this.getButtonsDialog(CONFIRM_METHOD.WITHOUT_CONFIRMATION, tx);
        this.props.refunds.showConfirmDialog(true);
    }

    getButtonsDialog(method, tx) {
        let buttons = null;
        switch (method) {
            case CONFIRM_METHOD.WITHOUT_CONFIRMATION:
                buttons = (<>
                    <Button variant="primary" onClick={this.closeConfirmDialog}>
                        Aceptar
                    </Button>
                </>);
                break;
            case CONFIRM_METHOD.WITH_CONFIRMATION:
                buttons = (<>
                    <Button variant="secondary" onClick={this.closeConfirmDialog}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={
                        () => tabActive === TAB.PAGO_DISTANCIA ? this.remoteRefund(tx) : this.forceRefund(tx.txId)
                    }>
                        Confirmar
                    </Button>
                </>);
                break;
            case CONFIRM_METHOD.RETENTION:
                buttons = (<>
                    <Button variant="secondary" onClick={this.closeConfirmDialog}>Cancelar</Button>
                    <Button variant="primary" onClick={() => this.feeRetention(tx)}>Retener</Button>
                </>);
                break;
            default:
                break;
        }

        return buttons;
    }

    closeConfirmDialog() {
        this.props.refunds.showConfirmDialog(undefined);
    }

    forceRefund(id) {
        this.props.refunds.showAlertError(undefined);
        this.props.utils.setSpinner(true);
        this.closeConfirmDialog();
        forceRefund(id)
            .then(resp => this.handleForceRefundResponse(resp, id))
            .catch(e => this.handleForceRefundError(e, id));
    }

    handleForceRefundResponse(resp, id) {
        if (resp.status === '0') {
            let msg = `Se ha producido el siguiente error al forzar la devolución para la transacción con el ID ${id}: ${resp.statusInfo}`;
            this.showConfirmationDialog('', msg, CONFIRM_METHOD.WITHOUT_CONFIRMATION, COLOR.ERROR, undefined);
        } else {
            let msg = `La transacción con el ID ${id} ha sido devuelta exitosamente`;
            let details = this.getRefundDetails(resp, undefined);
            this.showConfirmationDialog('', msg, CONFIRM_METHOD.WITHOUT_CONFIRMATION, COLOR.SUCCESS, details);

            let index = this.props.refundState.transactionIds.indexOf(id);
            this.props.refundState.transactionIds.splice(index, 1);

            if(this.props.refundState.transactionIds.length === 0) {
                this.props.refunds.setTransactionIds(undefined);
                this.props.refunds.setTxHistory(undefined);
            } else {
                this.getRefunds(this.props.refundState.transactionIds);
            }
        }
        this.props.utils.setSpinner(false);
    }

    handleForceRefundError(e, id) {
        let msg = `Se ha producido el siguiente error al forzar la devolución para la transacción con el id ${id}: ${e}, por favor contacte al administrador`;
        this.showConfirmationDialog('', msg, CONFIRM_METHOD.WITHOUT_CONFIRMATION, COLOR.ERROR);
        this.props.utils.setSpinner(false);
    }

    partialRefund(tx, amountPartialRefund) {
        amountPartialRefund = typeof amountPartialRefund === "number"? amountPartialRefund: parseFloat(amountPartialRefund.toString());
        if (amountPartialRefund > totalAmountTx || amountPartialRefund <= 0) {
            let invalidAmount = amountPartialRefund > totalAmountTx ?
                `La transacción tiene un monto a devolver de ${this.formatCash(totalAmountTx)} la devolución no puede exceder ese monto` :
                `Favor de ingresar un valor mayor a 0`;

            this.props.refunds.showConfirmDialog(false);
            bodyText = (<>
                <Alert variant={COLOR.ERROR}>
                    <b>{invalidAmount}</b>
                </Alert>
            </>);
            this.props.refunds.showConfirmDialog(true);
        } else {
            this.props.refunds.showConfirmDialog(false);
            this.props.refunds.showAlertError(undefined);
            this.props.utils.setSpinner(true);
            this.closeConfirmDialog();

            let sale = this.getSaleByTx(tx, amountPartialRefund);
            partialRefund(sale)
                .then(resp => this.handlePartialRefundResponse(resp, tx,  amountPartialRefund))
                .catch(e => this.handleGeneralError(e));
        }
    }

    handlePartialRefundResponse(resp, tx, amountPartialRefund) {
        this.props.utils.setSpinner(false);
        if (resp.status === '0') {
            let msg = `Se ha producido el siguiente error al realizar la devolución parcial para la transacción con el ID ${tx.txId}: ${resp.statusInfo}`;
            this.showConfirmationDialog('', msg, CONFIRM_METHOD.WITHOUT_CONFIRMATION, COLOR.ERROR, undefined);
        } else {
            let msg = `La transacción con el ID ${tx.txId} ha sido parcialmente devuelta exitosamente`;
            let details = this.getRefundDetails(resp, amountPartialRefund);
            this.showConfirmationDialog('', msg, CONFIRM_METHOD.WITHOUT_CONFIRMATION, COLOR.SUCCESS, details);

            this.props.refunds.setPartialTransactionIds(undefined);
            this.props.refunds.setPartialTxHistory(undefined);
        }
    }

    handleGeneralError(e) {
        console.error(e);
        this.props.utils.setSpinner(false);
        this.showConfirmationDialog('', e.toString(), CONFIRM_METHOD.WITHOUT_CONFIRMATION, COLOR.ERROR, undefined);
    }

    remoteRefund(tx) {
        this.props.refunds.showAlertError(undefined);
        this.props.utils.setSpinner(true);
        this.closeConfirmDialog();

        remoteRefund(tx)
            .then(resp => this.handleRemoteRefundResponse(resp, tx))
            .catch(err => this.handleRemoteRefundError(err, tx));
    }

    handleRemoteRefundResponse(resp, tx) {
        if (resp.status === '1') {
            let msg = `La transacción de pago a distancia con el ID ${tx.txId} ha sido devuelta exitosamente`;
            let details = this.getRefundDetails(resp, undefined);
            this.showConfirmationDialog('', msg, CONFIRM_METHOD.WITHOUT_CONFIRMATION, COLOR.SUCCESS, details);

            this.props.refunds.setRemoteTransactionIds(undefined);
            this.props.refunds.setRemoteTxHistory(undefined);
        } else {
            let msg = `Se ha producido el un error al realizar la devolución para la transacción de pago a distancia con el ID ${tx.txId}: ${resp.statusInfo}`;
            this.showConfirmationDialog('', msg, CONFIRM_METHOD.WITHOUT_CONFIRMATION, COLOR.ERROR, undefined);
        }

        this.props.utils.setSpinner(false);
    }

    handleRemoteRefundError(err, tx) {
        console.error('Respuesta devolución pago a distancia', err);
        this.props.utils.setSpinner(false);

        let msg = `Se ha producido un error con la transacción de pago a distancia con el ID ${tx.txId}: ${err}`;
        this.showConfirmationDialog('', msg, CONFIRM_METHOD.WITHOUT_CONFIRMATION, COLOR.ERROR, undefined);
    }

    feeRetention(tx) {
        this.props.refunds.showConfirmDialog(false);
        this.props.utils.setSpinner(true);

        feeRetention(tx.txId)
            .then(resp => this.handleSuccessRetention(resp, tx.txId))
            .catch(e => this.handleGeneralError(e));
    }

    handleSuccessRetention(resp, id) {
        this.props.utils.setSpinner(false);

        if (resp.status === 0) {
            this.showConfirmationDialog('', resp.statusInfo, CONFIRM_METHOD.WITHOUT_CONFIRMATION, COLOR.ERROR, undefined);
        } else {
            let msg = `Se ha realizado la retención por $${resp.statusInfo} para la transacción con el ID ${id}`;
            this.showConfirmationDialog('', msg, CONFIRM_METHOD.WITHOUT_CONFIRMATION, COLOR.SUCCESS, undefined);
        }
    }

    getSaleByTx(tx, amountPartialRefund) {
        let sale = {
            id: tx.deviceId,
            saleid: tx.txId,
            price: amountPartialRefund,
            tip: tx.tip,
            latitude: tx.latitude,
            longitude: tx.length,
            captureType: tx.entryMode
        };

        return sale;
    }

    getRefundDetails(refund, devolucion) {
        return (
            <Table striped bordered hover>
                <tbody>
                <tr>
                    <td>Estatus</td>
                    <td>{refund.statusInfo}</td>
                </tr>
                <tr>
                    <td>ID Transacción</td>
                    <td>{refund.result.transaccionId}</td>
                </tr>
                <tr>
                    <td>Autorización</td>
                    <td>{refund.result.autorization}</td>
                </tr>
                <tr>
                    <td>Nombre</td>
                    <td>{refund.result.name}</td>
                </tr>
                <tr>
                    <td>PAN</td>
                    <td>{refund.result.pan}</td>
                </tr>
                {
                    devolucion ?
                        <tr>
                            <td>Devolución</td>
                            <td>{this.formatCash(devolucion)}</td>
                        </tr> : null
                }
                </tbody>
            </Table>
        );
    }

    searchTransactions() {
        this.props.refunds.showAlertError(undefined);
        this.validationsIds()
            .then(errors => this.handleSearchTransactions(errors))
            .catch(e => this.handleSearchTransactionsError(e));
    }

    handleSearchTransactions(errors) {
        if(errors) {
            this.props.refunds.showAlertError(
                (this.props.refundState.alertError ? this.props.refundState.alertError : '')
                + `${errors}. `);
        } else {
            let transactionIds =
                (tabActive === TAB.FORZADAS) ? this.props.refundState.transactionIds :
                (tabActive === TAB.PARCIALES) ? this.props.refundState.partialTransactionIds :
                (tabActive === TAB.MASIVAS) ? this.props.getRefunds.fileTransactionIds:
                (tabActive === TAB.PAGO_DISTANCIA) ? this.props.refundState.remoteTransactionIds :
                (tabActive === TAB.RETENCIONES) ? this.props.refundState.retentionsTransactionIds : [];

            this.getRefunds(transactionIds, tabActive === TAB.RETENCIONES);
        }
    }

    handleSearchTransactionsError(e) {
        console.error('Error searchTransactiions', e);
        this.props.refunds.showAlertError(e);
    }

    validationsIds() {
        let transactionIds = [];
        transactionIds =
            tabActive === TAB.FORZADAS ?
                this.props.refundState.transactionIds :
                tabActive === TAB.PARCIALES ?
                    this.props.refundState.partialTransactionIds :
                    tabActive === TAB.MASIVAS ?
                        this.props.refundState.fileTransactionIds :
                        tabActive === TAB.PAGO_DISTANCIA ?
                            this.props.refundState.remoteTransactionIds :
                            tabActive === TAB.RETENCIONES ?
                                this.props.refundState.retentionsTransactionIds : [];

        return new Promise(resolve => {
            if (transactionIds) {

                let idsError = [];
                transactionIds.forEach((id, index) => {
                    if (!(+id)) {
                        idsError.push(id);
                    }

                    if(transactionIds.length === index + 1) {
                        resolve(idsError.length > 0 ?
                            `Los ids (${idsError}) son datos incorrectos, debe ingresar un ids numéricos`:
                            '');
                    }
                });
            }
            resolve(undefined);
        });
    }

    setIds(inputData) {
        let transactionIds = [];
        if (typeof inputData === 'string') {
            if(inputData.includes(',')) {
                let inputDataArray = inputData.split(',');
                inputDataArray.forEach(id => transactionIds.push(id));
            } else {
                inputData !== '' ? transactionIds.push(inputData) : transactionIds = undefined;
            }
        } else if (typeof  inputData === "object") {
            inputData.forEach(id => transactionIds.push(id));
        }

        switch (tabActive) {
            case TAB.FORZADAS:
                this.props.refunds.setTransactionIds(transactionIds);
                break;
            case TAB.PARCIALES:
                this.props.refunds.setPartialTransactionIds(transactionIds);
                break;
            case TAB.MASIVAS:
                this.props.refunds.setFileTransactionIds(transactionIds);
                break;
            case TAB.PAGO_DISTANCIA:
                this.props.refunds.setRemoteTransactionIds(transactionIds);
                break;
            case TAB.RETENCIONES:
                this.props.refunds.setRetentionsTransactionIds(transactionIds);
                break;
            default:
                break;
        }
    }

    dataTable() {
        let dataTable = [];
        let refunds =
            tabActive === TAB.FORZADAS ?
                this.props.refundState.refunds :
                tabActive === TAB.PARCIALES ?
                    this.props.refundState.partialRefunds :
                    tabActive === TAB.MASIVAS ?
                        this.props.refundState.fileRefunds :
                        tabActive === TAB.PAGO_DISTANCIA ?
                            this.props.refundState.remoteRefunds :
                            tabActive === TAB.RETENCIONES ?
                                this.props.refundState.retentions : [];

        let activePage = this.props.utilsState.activePage;
        if (refunds) {
            refunds.sort((a, b) => a.txId > b.txId ? 1 : -1);
            let initData = (activePage - 1) * PAGINATION_LIMIT;
            let lastItem = (initData + PAGINATION_LIMIT) > refunds.length ? refunds.length : (initData + PAGINATION_LIMIT);

            for (let index = initData; index < lastItem; index++) {
                if(index === 0) {
                    totalAmountTx = refunds[index].amount + refunds[index].tip;
                } else {
                    totalAmountTx -= refunds[index].amount;
                }

                dataTable.push(
                    <>
                        <tr key={refunds[index].txId}>
                            <td>{refunds[index].txId}</td>
                            <td>{refunds[index].txType}</td>
                            <td>{this.formatCash(refunds[index].amount)}</td>
                            <td>{refunds[index].date ? this.formatDate(new Date(refunds[index].date)) : ''}</td>
                            <td>{refunds[index].entryMode}</td>
                            <td>{refunds[index].authNumber}</td>
                            <td style={{textAlign: 'center'}}>
                                {
                                    this.getButtonAction(refunds[index])
                                }
                            </td>
                        </tr>
                        {this.showMore(refunds[index])}
                    </>
                );
            }
        }

        return dataTable;
    }

    getButtonAction(refund) {
        let loggedUser = this.props.appState.loggedUser;
        let isFeeRetention = loggedUser && (loggedUser.isFeeRetention || loggedUser.isAdmin);

        return (<>
            {
                refund.txType === REFUND_TYPE.SALE ?
                    (
                        tabActive === TAB.FORZADAS || tabActive === TAB.MASIVAS ?
                            <Button variant='primary'
                                    onClick={() => this.showConfirmationDialogForce(refund)}>Forzar</Button> :
                            tabActive === TAB.PARCIALES ?
                                <Button variant='primary'
                                        onClick={() => this.showConfirmationDialogPartialsRefund(refund)}>Devolución</Button> :
                                tabActive === TAB.PAGO_DISTANCIA ?
                                    <Button variant='primary'
                                            onClick={() => this.showConfirmationDialogRemoteRefund(refund)}>Devolución</Button> : null
                    ) : null
            }

            {
                tabActive === TAB.RETENCIONES && isFeeRetention && refund.txType === REFUND_TYPE.SALE ?
                    <ButtonGroup className="ml-2">
                        <Button variant='danger' onClick={() => this.showConfirmationDialogRetention(refund)}>Retener</Button>
                    </ButtonGroup>: null
            }
        </>);
    }

    showMore(txHistory) {
        return (
            txHistory.txType === REFUND_TYPE.SALE ?
                <tr key={txHistory.txId + '-'}>
                    <td colSpan={7}>
                        <Accordion>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                Ver más...
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Table striped bordered hover>
                                    <tbody>
                                    <tr>
                                        <td>Tip</td>
                                        <td>{this.formatCash(txHistory.tip)}</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td>{txHistory.status}</td>
                                    </tr>
                                    <tr>
                                        <td>Card type</td>
                                        <td>{txHistory.cardType}</td>
                                    </tr>
                                    <tr>
                                        <td>Processed</td>
                                        <td>{txHistory.processed}</td>
                                    </tr>
                                    <tr>
                                        <td>Fee</td>
                                        <td>{txHistory.fee}</td>
                                    </tr>
                                    <tr>
                                        <td>B24 Capture</td>
                                        <td>{txHistory.b24Capture}</td>
                                    </tr>
                                    <tr>
                                        <td>Refund</td>
                                        <td>{txHistory.refund}</td>
                                    </tr>
                                    <tr>
                                        <td>Card number</td>
                                        <td>{txHistory.cardNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Latitude</td>
                                        <td>{txHistory.latitude}</td>
                                    </tr>
                                    <tr>
                                        <td>Length</td>
                                        <td>{txHistory.length}</td>
                                    </tr>
                                    <tr>
                                        <td>Business name</td>
                                        <td>{txHistory.businessName}</td>
                                    </tr>
                                    <tr>
                                        <td>Trade name</td>
                                        <td>{txHistory.tradeName}</td>
                                    </tr>
                                    <tr>
                                        <td>RFC</td>
                                        <td>{txHistory.rfc}</td>
                                    </tr>
                                    <tr>
                                        <td>User city</td>
                                        <td>{txHistory.userCity}</td>
                                    </tr>
                                    <tr>
                                        <td>User state</td>
                                        <td>{txHistory.userState}</td>
                                    </tr>
                                    <tr>
                                        <td>Fiid</td>
                                        <td>{txHistory.fiid}</td>
                                    </tr>
                                    <tr>
                                        <td>Auth number</td>
                                        <td>{txHistory.authNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Device id</td>
                                        <td>{txHistory.deviceId}</td>
                                    </tr>
                                    <tr>
                                        <td>Device tag</td>
                                        <td>{txHistory.deviceTag}</td>
                                    </tr>
                                    <tr>
                                        <td>Device Status</td>
                                        <td>{txHistory.deviceStatus}</td>
                                    </tr>
                                    <tr>
                                        <td>Reader serial number</td>
                                        <td>{txHistory.readerSerialNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Reader Status</td>
                                        <td>{txHistory.readerStatus}</td>
                                    </tr>
                                    <tr>
                                        <td>Reader type</td>
                                        <td>{txHistory.readerType}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Accordion.Collapse>
                        </Accordion>
                    </td>
                </tr> : null
        );
    }

    pagination() {
        let pagination = [];
        let data =
            tabActive === TAB.FORZADAS ?
                this.props.refundState.refunds :
                tabActive === TAB.PARCIALES ?
                    this.props.refundState.partialRefunds :
                    tabActive === TAB.MASIVAS ?
                        this.props.refundState.fileRefunds :
                        tabActive === TAB.RETENCIONES ?
                            this.props.refundState.remoteRefunds : [];

        let activePage = this.props.utilsState.activePage;
        if (data && data.length >= PAGINATION_LIMIT) {
            let totalPage = this.getTotalPages();
            pagination.push(
                <Pagination key={1} className='Pagination'>
                    <Pagination.First onClick={this.goToPage}/>
                    <Pagination.Prev onClick={this.goToPage}/>
                    <Pagination.Item active={true}>
                        {activePage} de {totalPage}
                    </Pagination.Item>
                    <Pagination.Next onClick={this.goToPage}/>
                    <Pagination.Last onClick={this.goToPage}/>
                </Pagination>
            );
        }

        return pagination;
    }

    getTotalPages() {
        let data =
            tabActive === TAB.FORZADAS ?
                this.props.refundState.refunds :
                tabActive === TAB.PARCIALES ?
                    this.props.refundState.partialRefunds :
                    tabActive === TAB.MASIVAS ?
                        this.props.refundState.fileRefunds :
                        tabActive === TAB.PARCIALES ?
                            this.props.refundState.remoteRefunds :
                            tabActive === TAB.RETENCIONES ?
                                this.props.refundState.retentions : [];

        if (data === undefined || data.length <= PAGINATION_LIMIT) {
            return 1;
        }

        let pageNumber = data.length / PAGINATION_LIMIT;
        return data.length % PAGINATION_LIMIT === 0 ? pageNumber : Math.floor(pageNumber) + 1;
    }

    goToPage(event) {
        let action = event.currentTarget.getElementsByClassName("sr-only")[0].textContent;
        switch (action) {
            case ACTIONS.FIRST:
                this.props.utils.setActivePage(1);
                break;
            case ACTIONS.PREV:
                let prev = this.props.utilsState.activePage <= 1 ? 1 : (this.props.utilsState.activePage - 1);
                this.props.utils.setActivePage(prev);
                break;
            case ACTIONS.NEXT:
                let totalPages = this.getTotalPages();
                let next = this.props.utilsState.activePage >= totalPages ? totalPages : (this.props.utilsState.activePage + 1);
                this.props.utils.setActivePage(next);
                break;
            case ACTIONS.LAST:
                this.props.utils.setActivePage(this.getTotalPages());
                break;
            default:
                break;
        }
    }

    formatCash(cash) {
        return cash? cash.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'): cash;
    }

    formatDate(date) {
        if (date) {
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            month = month < 10 ? '0' + month : month;
            let day = date.getDate();
            day = day < 10 ? '0' + day : day;
            let hour = date.getHours();
            hour = hour < 10 ? '0' + hour : hour;
            let minutes = date.getMinutes();
            minutes = minutes < 10 ? '0' + minutes : minutes;

            return [[day, month, year].join('-'), [hour, minutes].join(':')].join(' ');
        }

        return '';
    }

    onChangeTabs(key) {
        tabActive = parseFloat(key);
        this.props.refunds.showAlertError(undefined);
    }

    handleFiles(files) {
        loadingText = 'Leyendo archivo...';
        this.props.utils.setSpinner(true);
        this.props.refunds.showAlertError(undefined);
        this.props.refunds.setFileTxHistory(undefined);
        let reader = new FileReader();

        this.readData(reader)
            .then(() => {
                this.splitTransactions(reader.result);
                this.searchTransactionsFromCSV();
            })
            .catch(e => this.handleFilesError(e));
        reader.readAsText(files[0]);
    }

    handleFilesError(e) {
        console.error(e);
        this.props.utils.setSpinner(false);
    }

    splitTransactions(data) {
        let transactions = [];

        let indexRefunds = -1;
        let rows = data.split('\n');
        let idsNaN = [];
        rows.forEach((row, indexRow) => {
            let rowData = row.split(',');
            rowData.forEach((cell, indexCell) => {
                cell = cell.toString().trim();
                if (indexRow === 0) {
                    if (cell.toString().toUpperCase() === 'DEVOLUCIONES') {
                        indexRefunds = indexCell;
                    }
                } else {
                    if (indexCell === indexRefunds) {
                        if (!(+cell) && cell !== "") {
                            idsNaN.push(cell);
                        } else {
                            transactions.push(cell);
                        }
                    }
                }
            });
        });

        if (idsNaN.length > 0) {
            let msg = `Los ids (${idsNaN}) son datos incorrectos, debe ingresar un ids numéricos`.replace(/,/g, ', ');
            this.props.refunds.showAlertError(
                (this.props.refundState.alertError ? this.props.refundState.alertError : '')
                + `${msg}. `);
        }
        this.props.refunds.setFileTransactionIds(transactions);
    }


    searchTransactionsFromCSV() {
        this.getRefundsFromFile(this.props.refundState.fileTransactionIds);
    }

    forceAll() {
        this.props.refunds.showAlertError(undefined);
        let transactions = [];
        this.props.refundState.fileRefunds.forEach(refund => {
            if (refund.txType === REFUND_TYPE.SALE) {
                transactions.push(refund.txId);
            }
        });

        if (transactions && transactions.length > 0) {
            loadingText = `Forzando transacción 0 de ${transactions.length}`;
            this.props.utils.setSpinner(true);

            let result = [];
            let index = 0;
            this.callForceAllRefunds(transactions, index,  result);
        }
    }

    callForceAllRefunds(transactions, index, result) {
        loadingText = `Forzando transacción ${index} de ${transactions.length}`;
        this.props.utils.setSpinner(true);

        forceRefund(transactions[index])
            .then(response => this.handleForceRefundFileResponse(response, transactions, index, result))
            .catch(e => this.handleForceRefundFileError(e, transactions, index,  result));
    }

    handleForceRefundFileResponse(response, transactions, index, result) {
        loadingText = `Forzando transacción ${index} de ${transactions.length}`;
        this.props.utils.setSpinner(true);

        result.push({
            id: transactions[index],
            color: response.status === "0"? COLOR.ERROR: COLOR.SUCCESS,
            body: response.statusInfo
        });

        if (transactions.length === index + 1) {
            this.props.utils.setSpinner(false);
            loadingText = undefined;
            this.showConfirmationDialogFile(result, transactions[index]);
        } else {
            index++;
            this.callForceAllRefunds(transactions, index, result);
        }
    }

    handleForceRefundFileError(e, transactions, index, result) {
        result.push({
            id: transactions[index],
            color: COLOR.ERROR,
            body: e
        });

        if (transactions.length === index + 1) {
            this.props.utils.setSpinner(false);
            loadingText = undefined;
            this.showConfirmationDialogFile(result);
        } else {
            index++;
            this.callForceAllRefunds(transactions, index, result);
        }
    }

    readData(reader) {
        return new Promise(resolve => {
            reader.onload = function(e) {
                let arrayTransactions = reader.result.split('\n');
                let transactionIds = [];
                arrayTransactions.forEach(transaction => {
                    if(!isNaN(transaction)) {
                        transactionIds.push(parseFloat(transaction));
                    }
                });
                resolve(transactionIds);
            }
        });
    }

    render() {
        return (
            <>
                <Card className="bg-info text-white">
                    <Card.Header>
                        <h4><b>Devoluciones</b></h4>
                    </Card.Header>
                </Card>

                <Modal
                    show={this.props.utilsState.spinner}
                    onHide={() => !this.props.utilsState.spinner}
                    backdrop='static'
                    keyboard={false}
                >
                    <Modal.Body>
                        <div>
                            {
                                loadingText ?
                                    <b> {loadingText} </b> :
                                    <b> Cargando... </b>
                            }
                            <Spinner animation="grow" size="sm" role="status">
                                <span className="sr-only"/>
                            </Spinner>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.props.refundState.confirmDialog}
                    onHide={() => this.closeConfirmDialog}
                    backdrop='static'
                    keyboard={false}>
                    {
                        headingText !== '' ?
                            <Modal.Header>
                                <Modal.Title>
                                    <Alert variant='dark'>
                                        <Alert.Heading>{headingText}</Alert.Heading>
                                    </Alert>
                                </Modal.Title>
                            </Modal.Header> : null
                    }
                    <Modal.Body>
                        {bodyText}
                    </Modal.Body>
                    <Modal.Footer>
                        {buttonsDialog}
                    </Modal.Footer>
                </Modal>

                {
                    this.props.refundState.alertError?
                        <Alert key={1} variant={COLOR.ERROR}  onClose={() => this.props.refunds.showAlertError(undefined)} dismissible>
                            <b>{this.props.refundState.alertError}</b>
                        </Alert>: null
                }

                <Alert>
                    <Tabs defaultActiveKey={TAB.FORZADAS} id="uncontrolled-tab-example" onSelect={this.onChangeTabs}>
                        <Tab eventKey={TAB.FORZADAS} title="Forzadas">
                            <Alert>
                                <Form>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">ID Transacción</Form.Label>
                                        <Col sm="4">
                                            <Form.Control type="text"
                                                          onKeyPress={event => {
                                                              if (event.which === ENTER_KEY) {
                                                                  event.preventDefault();
                                                              }
                                                          }}
                                                          onChange={val => this.setIds(val.target.value)}
                                                          value={this.props.refundState.transactionIds || ''}/>
                                        </Col>
                                    </Form.Group>

                                    <ButtonToolbar>
                                        <ButtonGroup className="mr-2">
                                            <Button variant='warning' onClick={this.searchTransactions}
                                                    disabled={this.props.refundState.transactionIds === undefined}>
                                                Buscar
                                            </Button>
                                        </ButtonGroup>
                                    </ButtonToolbar>
                                </Form>
                            </Alert>

                            <Table striped bordered hover>
                                <thead className="thead-dark">
                                <tr>
                                    <th>ID</th>
                                    <th>Tipo</th>
                                    <th>Monto</th>
                                    <th>Fecha</th>
                                    <th>Clave de operación</th>
                                    <th>Número de autorización</th>
                                    <th>Forzar devolución</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.dataTable()}
                                </tbody>
                            </Table>
                            {this.pagination(true)}
                        </Tab>

                        <Tab eventKey={TAB.PARCIALES} title="Parciales">
                            <Alert>
                                <Form>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">ID Transacción</Form.Label>
                                        <Col sm="4">
                                            <Form.Control type="text"
                                                          onKeyPress={event => {
                                                              if (event.which === ENTER_KEY) {
                                                                  event.preventDefault();
                                                              }
                                                          }}
                                                          onChange={val => this.setIds(val.target.value)}
                                                          value={this.props.refundState.partialTransactionIds || ''}/>
                                        </Col>
                                    </Form.Group>

                                    <ButtonToolbar>
                                        <ButtonGroup className="mr-2">
                                            <Button variant='warning' onClick={this.searchTransactions}
                                                    disabled={this.props.refundState.partialTransactionIds === undefined}>
                                                Buscar
                                            </Button>
                                        </ButtonGroup>
                                    </ButtonToolbar>
                                </Form>
                            </Alert>

                            <Table striped bordered hover>
                                <thead className="thead-dark">
                                <tr>
                                    <th>ID</th>
                                    <th>Tipo</th>
                                    <th>Monto</th>
                                    <th>Fecha</th>
                                    <th>Clave de operación</th>
                                    <th>Número de autorización</th>
                                    <th>Devolución parcial</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.dataTable()}
                                </tbody>
                            </Table>
                            {this.pagination(false)}
                        </Tab>

                        <Tab eventKey={TAB.MASIVAS} title="Devoluciones masivas">
                            <Alert>
                                <Form>
                                    <ButtonToolbar>
                                        <ButtonGroup className="mr-2">
                                            <ReactFileReader fileTypes={".csv"} handleFiles={this.handleFiles}>
                                                <Button variant='info'>Cargar CSV</Button>
                                            </ReactFileReader>
                                        </ButtonGroup>

                                        <ButtonGroup className="mr-2">
                                            <Button variant='warning' onClick={this.forceAll}
                                                    disabled={this.props.refundState.fileRefunds === undefined}>
                                                Forzar todo
                                            </Button>
                                        </ButtonGroup>
                                    </ButtonToolbar>
                                </Form>
                            </Alert>

                            <Table striped bordered hover>
                                <thead className="thead-dark">
                                <tr>
                                    <th>ID</th>
                                    <th>Tipo</th>
                                    <th>Monto</th>
                                    <th>Fecha</th>
                                    <th>Clave de operación</th>
                                    <th>Número de autorización</th>
                                    <th>Forzar devolución</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.dataTable()}
                                </tbody>
                            </Table>
                            {this.pagination(true)}
                        </Tab>

                        <Tab eventKey={TAB.PAGO_DISTANCIA} title="Devoluciones pago a distancia">
                            <Alert>
                                <Form>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">ID Transacción</Form.Label>
                                        <Col sm="4">
                                            <Form.Control type="text"
                                                          onKeyPress={event => {
                                                              if (event.which === ENTER_KEY) {
                                                                  event.preventDefault();
                                                              }
                                                          }}
                                                          onChange={val => this.setIds(val.target.value)}
                                                          value={this.props.refundState.remoteTransactionIds || ''}/>
                                        </Col>
                                    </Form.Group>

                                    <ButtonToolbar>
                                        <ButtonGroup className="mr-2">
                                            <Button variant='warning' onClick={this.searchTransactions}
                                                    disabled={this.props.refundState.remoteTransactionIds === undefined}>
                                                Buscar
                                            </Button>
                                        </ButtonGroup>
                                    </ButtonToolbar>
                                </Form>
                            </Alert>

                            <Table striped bordered hover>
                                <thead className="thead-dark">
                                <tr>
                                    <th>ID</th>
                                    <th>Tipo</th>
                                    <th>Monto</th>
                                    <th>Fecha</th>
                                    <th>Clave de operación</th>
                                    <th>Número de autorización</th>
                                    <th>Forzar devolución</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.dataTable()}
                                </tbody>
                            </Table>
                            {this.pagination(true)}
                        </Tab>

                        <Tab eventKey={TAB.RETENCIONES} title="Retenciones">
                            <Alert>
                                <Form>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">ID Transacción</Form.Label>
                                        <Col sm="4">
                                            <Form.Control type="text"
                                                          onKeyPress={event => {
                                                              if (event.which === ENTER_KEY) {
                                                                  event.preventDefault();
                                                              }
                                                          }}
                                                          onChange={val => this.setIds(val.target.value)}
                                                          value={this.props.refundState.retentionsTransactionIds || ''}/>
                                        </Col>
                                    </Form.Group>

                                    <ButtonToolbar>
                                        <ButtonGroup className="mr-2">
                                            <Button variant='warning' onClick={this.searchTransactions}
                                                    disabled={this.props.refundState.retentionsTransactionIds === undefined}>
                                                Buscar
                                            </Button>
                                        </ButtonGroup>
                                    </ButtonToolbar>
                                </Form>
                            </Alert>

                            <Table striped bordered hover>
                                <thead className="thead-dark">
                                <tr>
                                    <th>ID</th>
                                    <th>Tipo</th>
                                    <th>Monto</th>
                                    <th>Fecha</th>
                                    <th>Clave de operación</th>
                                    <th>Número de autorización</th>
                                    <th>Retención</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.dataTable()}
                                </tbody>
                            </Table>
                            {this.pagination(true)}
                        </Tab>
                    </Tabs>
                </Alert>

            </>
        );
    }
}