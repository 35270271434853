import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./utils/serviceWorker";
import {Auth} from "@aws-amplify/auth";
import {RestAPI} from "@aws-amplify/api-rest";
import {Storage} from "@aws-amplify/storage";
import AppContainer from "./containers/AppContainer";

import "./views/css/index.css";
import "bootstrap/dist/css/bootstrap.css";

Auth.configure({
    Auth: {
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_AWS_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_CLIENT_ID,
        identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
        oauth: {
            domain: process.env.REACT_APP_OAUTH_DOMAIN,
            redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_I0,
            redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_I0,
            scope: ["email", "openid"],
            responseType: "code"
        }
    }
});

RestAPI.configure({
    API: {
        endpoints: [
            {
                name: "admin",
                endpoint:
                    `${process.env.REACT_APP_AWS_ADMIN_ENDPOINT_URL}`,
                custom_header: async () => {
                    return {Authorization: (await Auth.currentSession()).idToken.jwtToken};
                }
            },
        ],
    }
});

Storage.configure({
    bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET,
    region: process.env.REACT_APP_AWS_REGION,
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID
});

ReactDOM.render(
    <AppContainer/>,
    document.getElementById("root")
);

serviceWorker.unregister();