import {ReduceStore} from 'flux/utils';
import AppDispatcher from '../dispatchers/AppDispatcher';
import {SET_USER, SET_READER} from '../actions/UserReaderActions';

const stateCallbacks = (function () {
    function setUser(state, action) {
        return Object.assign({}, state, {user: action.user});
    }

    function setReader(state, action) {
        return Object.assign({}, state, {reader: action.reader});
    }

    return {
        [Symbol.for(SET_USER)]: setUser,
        [Symbol.for(SET_READER)]: setReader
    }
}());

const UserReaderStore = class extends ReduceStore {
    constructor() {
        super(AppDispatcher);
        this.getInitialState = this.getInitialState.bind(this);
    }

    getInitialState() {
        return {
            user: undefined,
            reader: undefined
        }
    }
    reduce(state, action) {
        if (stateCallbacks[Symbol.for(action.type)]) {
            return stateCallbacks[Symbol.for(action.type)](state, action);
        } else {
            return state;
        }
    }
};

export default new UserReaderStore();