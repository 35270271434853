import {ReduceStore} from 'flux/utils';
import AppDispatcher from '../dispatchers/AppDispatcher';
import {
    SET_AUDITS,
    SET_END_DATE,
    SET_START_DATE,
    SET_USERNAME
} from "../actions/AuditActions";

const stateCallbacks = (function () {
    function setAudits(state, action) {
        return Object.assign({}, state, {audits: action.audits});
    }

    function setStartDate(state, action) {
        return Object.assign({}, state, {startDate: action.startDate})
    }

    function setEndDate(state, action) {
        return Object.assign({}, state, {endDate: action.endDate})
    }

    function setUsername(state, action) {
        return Object.assign({}, state, {username: action.username});
    }

    return {
        [Symbol.for(SET_AUDITS)]: setAudits,
        [Symbol.for(SET_START_DATE)]: setStartDate,
        [Symbol.for(SET_END_DATE)]: setEndDate,
        [Symbol.for(SET_USERNAME)]: setUsername
    }
}());

const AuditStore = class extends ReduceStore {
    constructor() {
        super(AppDispatcher);
        this.getInitialState = this.getInitialState.bind(this);
    }

    getInitialState() {
        return {
            audits: undefined,
            startDate: undefined,
            endDate: undefined,
            username: undefined
        };
    }

    reduce(state, action) {
        if (stateCallbacks[Symbol.for(action.type)]) {
            return stateCallbacks[Symbol.for(action.type)](state, action);
        } else {
            return state;
        }
    }
};

export default new AuditStore();