import {Container} from 'flux/utils';
import App from '../views/SecuredApp';
import AppStore from '../stores/AppStore';
import UtilsStore from "../stores/UtilsStore";
import AuditStore from '../stores/AuditStore';
import RefundsStore from "../stores/RefundsStore";
import AmxPspStore from "../stores/AmxPspStore";
import {
    setBalancerStatus,
    setLoggedUser,
    showAdminPanel,
    showAuditPanel,
    showBalancerActionConfirmDialog,
    showEndpointsPanel,
    showRefundsPanel,
    showAmxPspPanel,
    showUserReaderPanel,
    showB24OperationsPanel,
    showGsmfOperationsPanel
} from '../actions/AppActions';
import {setAudits, setEndDate, setStartDate, setUsername} from '../actions/AuditActions';
import {
    setTxHistory,
    setTransactionIds,
    setPartialTxHistory,
    setPartialTransactionIds,
    setFileTxHistory,
    setFileTransactionIds,
    setRemoteTxHistory,
    setRemoteTransactionIds,
    setRetentionsTxHistory,
    setRetentionsTransactionIds,
    showConfirmDialog,
    showAlertError
} from "../actions/RefundsAction";
import {setActivePage, setSpinner, showErrorDialog} from "../actions/UtilsActions";
import {
    setPspId,
    setPspCountryCode,
    setPspName,
    setPspSENumber,
    setPspSbmnSubmitterId,
    setPspSubmitterId,
    setPspSPC,
    setFiids,
    setNotes,
    setMdaCredit,
    setMdaDebit
} from "../actions/AmxPspActions";
import UserReaderStore from "../stores/UserReaderStore";
import {setReader, setUser} from "../actions/UserReaderActions";

function getStores() {
    return [AppStore, UtilsStore, AuditStore, RefundsStore, AmxPspStore, UserReaderStore];
}

function getState() {
    return {
        appState: AppStore.getState(),
        auditState: AuditStore.getState(),
        utilsState: UtilsStore.getState(),
        refundState: RefundsStore.getState(),
        amxPspState: AmxPspStore.getState(),
        userReaderState: UserReaderStore.getState(),
        showEndpointsPanel: showEndpointsPanel,
        showAdminPanel: showAdminPanel,
        setLoggedUser: setLoggedUser,
        setBalancerStatus: setBalancerStatus,
        showBalancerActionConfirmDialog: showBalancerActionConfirmDialog,
        showRefundsPanel: showRefundsPanel,
        showAuditPanel: showAuditPanel,
        showAmxPspPanel: showAmxPspPanel,
        showUserReaderPanel: showUserReaderPanel,
        showB24OperationsPanel: showB24OperationsPanel,
        showGsmfOperationsPanel: showGsmfOperationsPanel,
        utils: {
            setActivePage: setActivePage,
            setSpinner: setSpinner,
            showErrorDialog: showErrorDialog
        },
        audit: {
            setAudits: setAudits,
            setStartDate: setStartDate,
            setEndDate: setEndDate,
            setUsername: setUsername
        },
        refunds: {
            setTxHistory: setTxHistory,
            setTransactionIds: setTransactionIds,
            setPartialTxHistory: setPartialTxHistory,
            setPartialTransactionIds: setPartialTransactionIds,
            setFileTxHistory: setFileTxHistory,
            setFileTransactionIds: setFileTransactionIds,
            setRemoteTxHistory: setRemoteTxHistory,
            setRemoteTransactionIds: setRemoteTransactionIds,
            setRetentionsTxHistory: setRetentionsTxHistory,
            setRetentionsTransactionIds: setRetentionsTransactionIds,
            showConfirmDialog: showConfirmDialog,
            showAlertError: showAlertError
        },
        amxPsp: {
            setPspId: setPspId,
            setPspSubmitterId: setPspSubmitterId,
            setPspName: setPspName,
            setPspSENumber: setPspSENumber,
            setPspSpc: setPspSPC,
            setPspCountryCode: setPspCountryCode,
            setPspSbmnSubmitterId: setPspSbmnSubmitterId,
            setFiids: setFiids,
            setNotes: setNotes,
            setMdaCredit: setMdaCredit,
            setMdaDebit: setMdaDebit
        },
        userReader: {
            setUser: setUser,
            setReader: setReader
        }
    }
}

export default Container.createFunctional(App, getStores, getState);