import React, {Component} from 'react';
import {Button, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Auth} from "@aws-amplify/auth";
import {BalancerConfigurationPanel} from "./BalancerConfigurationPanel";
import {RefundsPanel} from './RefundsPanel';
import {AuditPanel} from "./AuditPanel";
import {AmxPspPanel} from "./AmxPspPanel";
import {UserReaderPanel} from "./UserReaderPanel";
import {B24OperationsPanel} from "./B24OperationsPanel";
import {GsmfOperationsPanel} from "./GsmfOperationsPanel";

export class MainPanel extends Component {
    constructor(props) {
        super(props);
        this.showEndpointsPanel = this.showEndpointsPanel.bind(this);
        this.showRefundsPanel = this.showRefundsPanel.bind(this);
        this.showAuditsPanel = this.showAuditsPanel.bind(this);
        this.showAmxPspPanel = this.showAmxPspPanel.bind(this);
        this.showUserReaderPanel = this.showUserReaderPanel.bind(this);
        this.showB24OperationsPanel = this.showB24OperationsPanel.bind(this);
        this.showGsmfOperationsPanel = this.showGsmfOperationsPanel.bind(this);
    }

    getLogOutText(loggedUser) {
        if (loggedUser && loggedUser.email) {
            return `Sign out ${loggedUser.email}`;
        } else return "";
    }

    showEndpointsPanel() {
        this.hidePanels();
        this.props.showEndpointsPanel(true);
    }

    showRefundsExtPanel() {
        this.hidePanels();
        this.props.showRefundsExtPanel(true);
    }

    showRefundsPanel() {
        this.hidePanels();
        this.props.showRefundsPanel(true);
    }

    showAuditsPanel() {
        this.hidePanels();
        this.props.showAuditPanel(true);
    }

    showAmxPspPanel() {
        this.hidePanels();
        this.props.showAmxPspPanel(true);
    }

    showUserReaderPanel() {
        this.hidePanels();
        this.props.showUserReaderPanel(true);
    }

    showB24OperationsPanel() {
        this.hidePanels();
        this.props.showB24OperationsPanel(true);
    }

    showGsmfOperationsPanel() {
        this.hidePanels();
        this.props.showGsmfOperationsPanel(true);
    }

    hidePanels() {
        this.props.showRefundsPanel(false);
        this.props.showEndpointsPanel(false);
        this.props.showAuditPanel(false);
        this.props.showAmxPspPanel(false);
        this.props.showUserReaderPanel(false);
        this.props.showB24OperationsPanel(false);
        this.props.showGsmfOperationsPanel(false);
    }

    render() {
        let loggedUser = this.props.appState.loggedUser;

        let isBalancer = loggedUser && loggedUser.isBalancer;
        let isAudit = loggedUser && loggedUser.isAudit;
        let isRefund = loggedUser && loggedUser.isRefund;
        let isAmexFiid = loggedUser && loggedUser.isAmexFiid;
        let isAdmin = loggedUser && loggedUser.isAdmin;
        let isReaderAssignment = loggedUser && loggedUser.isReaderAssignment;
        let isB24Refund = loggedUser && loggedUser.isB24Refund;
        let isAmexGsmf = loggedUser && loggedUser.isAmexGsmf;

        let componentShown =
            this.props.appState.showEndpointsPanel && (isBalancer || isAdmin) ? <BalancerConfigurationPanel {...this.props}/> :
            this.props.appState.showRefundsPanel && (isRefund || isAdmin) ? <RefundsPanel {...this.props}/> :
            this.props.appState.showAuditPanel && (isAudit || isAdmin) ? <AuditPanel {...this.props}/> :
            this.props.appState.showAmxPspPanel && (isAmexFiid || isAdmin) ? <AmxPspPanel {...this.props}/> :
            this.props.appState.showUserReaderPanel && (isReaderAssignment || isAdmin) ? <UserReaderPanel {...this.props}/> :
            this.props.appState.showB24OperationsPanel && (isB24Refund || isAdmin) ? <B24OperationsPanel {...this.props} /> :
            this.props.appState.showGsmfOperationsPanel && (isAmexGsmf || isAdmin) ? <GsmfOperationsPanel {...this.props} />: null;

        return (
            <div>
                <Navbar bg="dark" variant="dark" expand="md">
                    <Navbar.Brand>Billpocket Core Handler</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <NavDropdown title="Operaciones" id="basic-nav-dropdown">
                                <NavDropdown.Item onClick={this.showEndpointsPanel} disabled={!isBalancer && !isAdmin}>Configuración del balanceador</NavDropdown.Item>
                                <NavDropdown.Item onClick={this.showRefundsPanel} disabled={!isRefund && !isAdmin}>Devoluciones</NavDropdown.Item>
                                <NavDropdown.Item onClick={this.showAuditsPanel} disabled={!isAudit && !isAdmin}>Logs de auditoría</NavDropdown.Item>
                                <NavDropdown.Item onClick={this.showAmxPspPanel} disabled={!isAmexFiid && !isAdmin}>Afiliaciones Amex</NavDropdown.Item>
                                <NavDropdown.Item onClick={this.showUserReaderPanel} disabled={!isReaderAssignment && !isAdmin}>Asignación de lectores</NavDropdown.Item>
                                <NavDropdown.Item onClick={this.showB24OperationsPanel} disabled={!isB24Refund }>Operaciones B24</NavDropdown.Item>
                                <NavDropdown.Item onClick={this.showGsmfOperationsPanel} disabled={!isAmexGsmf }>Operaciones GSMF</NavDropdown.Item>
                                <NavDropdown.Divider/>
                                <NavDropdown.Item>Configuración</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                    <Button variant="danger"
                            onClick={() => Auth.signOut()}>{this.getLogOutText(this.props.appState.loggedUser)}</Button>
                </Navbar>
                <div>
                    {componentShown}
                </div>
            </div>
        );
    }
}