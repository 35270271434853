import React, {Component} from 'react';
import {
    Alert,
    Button,
    ButtonGroup,
    ButtonToolbar,
    Card,
    Dropdown,
    DropdownButton,
    Modal
} from "react-bootstrap";
import {getBalancerStatus, switchBaseEndpoint, switchLoadBalancerActivation} from "../../api/CoreHandlerApi";
import {
    ACTION_STATUS_CONFIRMED,
    ACTION_STATUS_SUCCESS,
    ACTION_TYPE_SWITCH_BALANCER_ACTIVATION,
    ACTION_TYPE_SWITCH_BASE_ENDPOINT
} from "../../utils/CoreHandlerUtils";

let balancerStatus = {};

export class BalancerConfigurationPanel extends Component {
    constructor(props) {
        super(props);
        this.fetchBalancerStatus = this.fetchBalancerStatus.bind(this);
        this.onSwitchBalancerActivation = this.onSwitchBalancerActivation.bind(this);
        this.executeBalancerSwitchActivation = this.executeBalancerSwitchActivation.bind(this);
        this.getConfirmationDialog = this.getConfirmationDialog.bind(this);
        this.onConfirmExecuteAction = this.onConfirmExecuteAction.bind(this);
        this.onSwitchBaseEndpoint = this.onSwitchBaseEndpoint.bind(this);
        this.onError = this.onError.bind(this);
    }

    componentDidMount() {
        this.getBalancerData(undefined);
    }

    fetchBalancerStatus(key) {
        this.getBalancerData(key);
    }

    getBalancerData(key) {
        return getBalancerStatus(key).then(status => {
            if (key) {
                let balancers = this.props.appState.balancerStatus;
                let index = balancers.findIndex(b => b.name === key);
                balancers[index] = status[0];
                this.props.setBalancerStatus(balancers);
            } else {
                this.props.setBalancerStatus(status);
            }
            return status;
        }).catch(this.onError);
    }

    onError(err) {
        console.error(err);
    }

    onSwitchBalancerActivation(key) {
        let balancers = this.props.appState.balancerStatus;
        balancerStatus = balancers.find(x => x.name === key);

        this.props.showBalancerActionConfirmDialog({
            actionType: ACTION_TYPE_SWITCH_BALANCER_ACTIVATION,
            key: key
        });
    }

    onSwitchBaseEndpoint(key) {
        let balancers = this.props.appState.balancerStatus;
        balancerStatus = balancers.find(x => x.name === key);

        this.props.showBalancerActionConfirmDialog({
            actionType: ACTION_TYPE_SWITCH_BASE_ENDPOINT,
            key: key
        });
    }

    executeBalancerSwitchActivation() {
        let action = this.props.appState.balancerAction;

        try {
            action.status = ACTION_STATUS_CONFIRMED;
            this.props.showBalancerActionConfirmDialog(action);

            switchLoadBalancerActivation(action.key).then(resp => {
                balancerStatus = this.props.appState.balancerStatus;
                action.responseText = `El balanceador de carga ha sido ${resp.isEnabled ? 'activado': 'desactivado'}`;
                action.status = ACTION_STATUS_SUCCESS;

                this.props.showBalancerActionConfirmDialog(action);
                this.fetchBalancerStatus(action.key);
                return resp;
            });
        } catch (err) {
            action.responseText = (err && err.message ? err.message : 'There was an error switching the balancer');
            this.props.showBalancerActionConfirmDialog(action);
        }

        setTimeout(() => {
            this.props.showBalancerActionConfirmDialog(undefined);
        }, 1000 * 5);
    }

    executeSwitchBaseEndpoint() {
        let action = this.props.appState.balancerAction;

        try {
            action.status = ACTION_STATUS_CONFIRMED;
            this.props.showBalancerActionConfirmDialog(action);

            switchBaseEndpoint(action.key).then((resp) => {
                balancerStatus = this.props.appState.balancerStatus;
                action.responseText = `El endpoint base a cambiado a: ${resp.endpoint}`;
                action.status = ACTION_STATUS_SUCCESS;

                this.props.showBalancerActionConfirmDialog(action);
                this.fetchBalancerStatus(action.key);
                return resp;
            });
        } catch (err) {
            action.responseText = (err && err.message ? err.message : 'There was an error switching the balancer');
            this.props.showBalancerActionConfirmDialog(action);
        }

        setTimeout(() => {
            this.props.showBalancerActionConfirmDialog(undefined);
        }, 1000 * 5);
    }

    onConfirmExecuteAction() {
        let action = this.props.appState.balancerAction;
        this.props.showBalancerActionConfirmDialog(undefined);

        if (action && action.actionType) {
            if (action.actionType === ACTION_TYPE_SWITCH_BALANCER_ACTIVATION) {
                this.executeBalancerSwitchActivation();
            } else if (action.actionType === ACTION_TYPE_SWITCH_BASE_ENDPOINT) {
                this.executeSwitchBaseEndpoint();
            }
        }
    }

    getConfirmationDialog() {
        let closeDialog = () => this.props.showBalancerActionConfirmDialog(undefined);
        let action = this.props.appState.balancerAction;

        let headerText = undefined;
        let modalBody = undefined;

        let confirmButtonDisabled = false;

        if (action) {
            confirmButtonDisabled = [ACTION_STATUS_CONFIRMED, ACTION_STATUS_SUCCESS].includes(action.status);

            if (action.responseText !== undefined) {
                let alertStyle = action.status === ACTION_STATUS_SUCCESS ? 'success' : 'warning';

                modalBody = (
                    <Modal.Body>
                        <Alert variant={alertStyle}>
                            <b>{action.responseText}</b>
                        </Alert>
                    </Modal.Body>
                );
            }

            switch (action.actionType) {
                case ACTION_TYPE_SWITCH_BALANCER_ACTIVATION:
                    headerText = balancerStatus.name ? `Confirmar ${balancerStatus.isEnabled ? 'desactivación' : 'activación'} del balanceador ${balancerStatus.name}` : undefined;
                    break;
                case ACTION_TYPE_SWITCH_BASE_ENDPOINT:
                    headerText = balancerStatus.name ? `Confirmar cambio de endpoint base actual: ${balancerStatus.endpoint}` : undefined;
                    break;

                default:
                    break;
            }
        }

        return (
            <div>
                <Modal
                    show={action !== undefined}
                    onHide={closeDialog}
                    backdrop="static"
                    keyboard={false}>
                    {
                        headerText ?
                            <Modal.Header>
                                <Modal.Title>
                                    <Alert variant="danger">
                                        <Alert.Heading>{headerText}</Alert.Heading>
                                    </Alert>
                                </Modal.Title>
                            </Modal.Header> : null
                    }
                    {modalBody}
                    <Modal.Footer>
                        {
                            headerText
                                ?
                                <>
                                    <Button variant="secondary" onClick={closeDialog}>
                                        Cancelar
                                    </Button>
                                    <Button variant="primary" onClick={this.onConfirmExecuteAction}
                                            disabled={confirmButtonDisabled}>Confirmar</Button>
                                </>
                                :
                                <Button variant="primary" onClick={closeDialog}>
                                    Aceptar
                                </Button>
                        }
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

    getCardBody(status) {
        if (status && status.endpoint && status.isEnabled !== undefined) {
            let loggedUser = this.props.appState.loggedUser;

            let isAdmin = loggedUser && loggedUser.isAdmin !== undefined
                ? loggedUser.isAdmin
                : false;
            let isBalancer = loggedUser && loggedUser.isBalancer !== undefined
                ? loggedUser.isBalancer
                : false;

            let statusAlert = status.isEnabled ? (
                <Alert variant="success">
                    <Alert.Heading>Balanceador de carga <b>habilitado</b>, endpoint
                        base: <b>{`[${status.endpoint}]`}</b></Alert.Heading>
                </Alert>
            ) : (
                <Alert variant="danger">
                    <Alert.Heading>Balanceador de carga <b>deshabilitado</b>, trafico
                        hacia: <b>{`[${status.endpoint}]`}</b></Alert.Heading>
                </Alert>
            );

            let action1ButtonText = status.isEnabled ? 'Deshabilitar balanceador' : 'Habilitar balanceador';

            return (
                <Card.Body>
                    <Card.Title>{statusAlert}
                        <ButtonToolbar aria-label="Toolbar with button groups" className="float-right">
                            <ButtonGroup className="mr-2" aria-label="Second group">
                                <Button variant="primary"
                                        onClick={() => this.fetchBalancerStatus(status.name)}>Refrescar</Button>
                            </ButtonGroup>

                            <ButtonGroup>
                                <DropdownButton as={ButtonGroup} title="Acciones" id="bg-nested-dropdown"
                                                variant="warning">
                                    <Dropdown.Item eventKey="1"
                                                   onClick={() => this.onSwitchBalancerActivation(status.name)}
                                                   disabled={!(isBalancer || isAdmin)}>{action1ButtonText}</Dropdown.Item>
                                    <Dropdown.Item eventKey="2"
                                                   onClick={() => this.onSwitchBaseEndpoint(status.name)}
                                                   disabled={!(isBalancer || isAdmin)}>Intercambiar endpoint base</Dropdown.Item>
                                </DropdownButton>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Card.Title>
                    <Card.Text>
                    </Card.Text>
                </Card.Body>
            );
        } else {
            return null;
        }
    }

    showCardBalancer = () => {
        let dataCard = [];
        let balancers = this.props.appState.balancerStatus ? this.props.appState.balancerStatus : [];

        balancers.sort((a, b) => a.name > b.name ? 1 : -1);
        balancers.forEach((card, index) => {
            dataCard.push(
                <Card className="bg-info text-white m-2" key={index}>
                    <Card.Header><h3><strong>{card.name}</strong></h3></Card.Header>
                    {this.getCardBody(card)}
                </Card>
            );
        });

        return(<>
            {dataCard}
            {this.getConfirmationDialog()}
        </>);
    }

    render() {
        return (<>
            {this.showCardBalancer()}
        </>);
    }
}