import React, {Component} from 'react';
import {
    Alert,
    Button,
    ButtonGroup,
    ButtonToolbar,
    Card,
    Col,
    Form,
    Modal, Pagination,
    Row,
    Spinner,
    Table
} from "react-bootstrap";
import {amexDataByFiid, getFiids, setAmxPsp} from "../../api/CoreHandlerApi";

let isChangeSeNumber = false;
let errorColor = 'danger';

const PAGINATION_LIMIT = 5;
const ACTIONS = {
    FIRST: 'First',
    PREV: 'Previous',
    NEXT: 'Next',
    LAST: 'Last'
};

const PSP_ID = '000079';
const PSP_SUBMITTER_ID = '097BPK';
const PSP_NAME = 'BILLPOCKET';
const PSP_SPC = '298';
const PSP_COUNTRY_CODE = 'MX';
const PSP_SBMN_SUBMITTER_ID = '6887104949';

export class AmxPspPanel extends Component {

    constructor(props) {
        super(props);
        this.saveFiid = this.saveFiid.bind(this);
        this.validateSENumber = this.validateSENumber.bind(this);
        this.getFiids = this.getFiids.bind(this);
        this.pagination = this.pagination.bind(this);
        this.goToPage = this.goToPage.bind(this);
    }

    componentDidMount() {
        isChangeSeNumber = false;
        errorColor = 'danger';
        this.props.utils.setActivePage(1);
        this.props.amxPsp.setPspId(PSP_ID);
        this.props.amxPsp.setPspSubmitterId(PSP_SUBMITTER_ID);
        this.props.amxPsp.setPspName(PSP_NAME);
        this.props.amxPsp.setPspSpc(PSP_SPC);
        this.props.amxPsp.setPspCountryCode(PSP_COUNTRY_CODE);
        this.props.amxPsp.setPspSbmnSubmitterId(PSP_SBMN_SUBMITTER_ID);
        this.props.amxPsp.setPspSENumber(undefined);

        this.getFiids();
    }

    getFiids() {
        this.props.utils.setSpinner(true);
        getFiids()
            .then(resp => this.setFiids(resp))
            .catch(e => this.showError(e));
    }

    setFiids(fiids) {
        this.getAmexDataByFiid(fiids, 0);
    }

    getAmexDataByFiid(fiids, index) {
        amexDataByFiid(fiids[index].pspSENumber)
            .then(response => this.handleFiidData(response, fiids, index))
            .catch(e => this.handleErrorFiids(e))
    }

    handleFiidData(response, fiids, index) {
        if (response.status === 1) {
            let amexFiid = response.statusInfo;

            fiids[index] = {
                ...fiids[index],
                notes: amexFiid ? amexFiid.notes : '',
                mdaCredit: amexFiid ? amexFiid.mdaCredito : '',
                mdaDebit: amexFiid ? amexFiid.mdaDebito : ''
            }

            this.props.utils.setActivePage(1);
            this.props.amxPsp.setFiids(fiids);
        }

        if(fiids.length === index + 1) {
            this.props.utils.setSpinner(false);
        } else {
            this.getAmexDataByFiid(fiids, index + 1);
        }
    }

    showError(e) {
        this.props.utils.setSpinner(false);
    }

    validateSENumber() {
        if (isChangeSeNumber) {
            return !!this.props.amxPspState.pspSENumber;
        }

        return true;
    }

    saveFiid() {
        isChangeSeNumber = true;
        if (this.validateSENumber()) {
            this.props.utils.setSpinner(true);

            let amxPsp = this.setAmxPspValues();
            let prosaFiid = this.setB24FiidValues();
            setAmxPsp(amxPsp, prosaFiid)
                .then(resp => this.handleResponseFiids(resp, amxPsp))
                .catch(e => this.handleErrorFiids(e));
        }
    }

    handleResponseFiids(resp, amxPsp) {
        if (resp.status === 0 || resp.error > 0) {
            errorColor = 'danger';
        } else {
            errorColor = 'success';
            let fiids = this.props.amxPspState.fiids;
            fiids.push(amxPsp);
            this.props.amxPsp.setFiids(fiids);
            this.props.amxPspState.fiids.sort((a, b) => a.pspSENumber > b.pspSENumber ? 1 : -1);
            this.props.utils.setActivePage(1);
        }

        this.props.utils.showErrorDialog(resp.message ? resp.message : resp.statusInfo);
        this.props.utils.setSpinner(false);
    }

    handleErrorFiids(e) {
        errorColor = 'danger';
        this.props.utils.showErrorDialog(e.toString());
        this.props.utils.setSpinner(false);
    }

    setAmxPspValues() {
        return {
            pspId: this.props.amxPspState.pspId,
            pspName: this.props.amxPspState.pspName,
            pspSbmnSubmitterId: this.props.amxPspState.pspSbmnSubmitterId,
            pspSENumber: this.props.amxPspState.pspSENumber,
            pspSPC: this.props.amxPspState.pspSPC,
            pspCountryCode: this.props.amxPspState.pspCountryCode,
            pspSubmitterId: this.props.amxPspState.pspSubmitterId,
        };
    }

    setB24FiidValues() {
        return {
            fiid: this.props.amxPspState.pspSENumber,
            notes: this.props.amxPspState.notes,
            mdaCredit: this.props.amxPspState.mdaCredit,
            mdaDebit: this.props.amxPspState.mdaDebit
        }
    }

    dataTable() {
        let dataTable = [];
        let fiids = this.props.amxPspState.fiids;
        let activePage = this.props.utilsState.activePage;
        if (fiids) {
            fiids.sort((a, b) => a.id < b.id ? 1 : -1);
            let initData = (activePage - 1) * PAGINATION_LIMIT;
            let lastItem = (initData + PAGINATION_LIMIT) > fiids.length ? fiids.length : (initData + PAGINATION_LIMIT);

            for (let index = initData; index < lastItem; index++) {
                dataTable.push(
                    <>
                        <tr key={index}>
                            <td>{fiids[index].pspSENumber}</td>
                            <td>{fiids[index].pspId}</td>
                            <td>{fiids[index].pspSubmitterId}</td>
                            <td>{fiids[index].pspName}</td>
                            <td>{fiids[index].pspSPC}</td>
                            <td>{fiids[index].pspCountryCode}</td>
                            <td>{fiids[index].pspSbmnSubmitterId}</td>
                            <td>{fiids[index].notes}</td>
                            <td>{fiids[index].mdaCredit}</td>
                            <td>{fiids[index].mdaDebit}</td>
                        </tr>
                    </>
                );
            }
        }

        return dataTable;
    }

    pagination() {
        let pagination = [];
        let data = this.props.amxPspState.fiids;

        let activePage = this.props.utilsState.activePage;
        if (data && data.length >= PAGINATION_LIMIT) {
            let totalPage = this.getTotalPages();
            pagination.push(
                <Pagination key={1} className='Pagination'>
                    <Pagination.First onClick={this.goToPage}/>
                    <Pagination.Prev onClick={this.goToPage}/>
                    <Pagination.Item active={true}>
                        {activePage} de {totalPage}
                    </Pagination.Item>
                    <Pagination.Next onClick={this.goToPage}/>
                    <Pagination.Last onClick={this.goToPage}/>
                </Pagination>
            );
        }

        return pagination;
    }

    getTotalPages() {
        let data = this.props.amxPspState.fiids;

        if (data === undefined || data.length <= PAGINATION_LIMIT) {
            return 1;
        }

        let pageNumber = data.length / PAGINATION_LIMIT;
        return data.length % PAGINATION_LIMIT === 0 ? pageNumber : Math.floor(pageNumber) + 1;
    }

    goToPage(event) {
        let action = event.currentTarget.getElementsByClassName("sr-only")[0].textContent;
        switch (action) {
            case ACTIONS.FIRST:
                this.props.utils.setActivePage(1);
                break;
            case ACTIONS.PREV:
                let prev = this.props.utilsState.activePage <= 1 ? 1 : (this.props.utilsState.activePage - 1);
                this.props.utils.setActivePage(prev);
                break;
            case ACTIONS.NEXT:
                let totalPages = this.getTotalPages();
                let next = this.props.utilsState.activePage >= totalPages ? totalPages : (this.props.utilsState.activePage + 1);
                this.props.utils.setActivePage(next);
                break;
            case ACTIONS.LAST:
                this.props.utils.setActivePage(this.getTotalPages());
                break;
            default:
                break;
        }
    }

    render() {
        return (<>
            <Card className="bg-info text-white">
                <Card.Header>
                    <h4><b>Afiliaciones American Express</b></h4>
                </Card.Header>
            </Card>

            <Modal
                show={this.props.utilsState.spinner}
                onHide={() => !this.props.utilsState.spinner}
                backdrop='static'
                keyboard={false}
            >
                <Modal.Body>
                    <b> Cargando... </b>
                    <Spinner animation="grow" size="sm" role="status">
                        <span className="sr-only"/>
                    </Spinner>
                </Modal.Body>
            </Modal>

            <Modal
                show={this.props.utilsState.errorDialog}
                onHide={() => this.props.utils.showErrorDialog(undefined)}
                backdrop='static'
                keyboard={false}>
                <Modal.Body>
                    <Alert variant={errorColor}>
                        <b>{this.props.utilsState.errorDialog}</b>
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => this.props.utils.showErrorDialog(undefined)}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Alert>
                <Form>
                    <Form.Group as={Row}>
                        <Form.Label column sm="4"><b>PSP SE Number <span style={{color: "red"}}>*</span></b></Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" onChange={ev => {
                                this.props.amxPsp.setPspSENumber(ev.target.value);
                                isChangeSeNumber = true;
                            }}  value={this.props.amxPspState.pspSENumber}/>
                            <span style={{color: "red"}} hidden={this.validateSENumber()}>Campo requerido</span>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm="4">PSP ID</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" onChange={ev => this.props.amxPsp.setPspId(ev.target.value)} value={this.props.amxPspState.pspId} disabled={true}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm="4">PSP Submitter ID</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" onChange={ev => this.props.amxPsp.setPspSubmitterId(ev.target.value)}  value={this.props.amxPspState.pspSubmitterId} disabled={true}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm="4">PSP Name</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" onChange={ev => this.props.amxPsp.setPspName(ev.target.value)}  value={this.props.amxPspState.pspName} disabled={true}/>
                        </Col>
                    </Form.Group>


                    <Form.Group as={Row}>
                        <Form.Label column sm="4">PSP SPC</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" onChange={ev => this.props.amxPsp.setPspSpc(ev.target.value)}  value={this.props.amxPspState.pspSPC} disabled={true}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm="4">PSP Country Code</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" onChange={ev => this.props.amxPsp.setPspCountryCode(ev.target.value)}  value={this.props.amxPspState.pspCountryCode} disabled={true}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm="4">PSP SBMN Submitter ID</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" onChange={ev => this.props.amxPsp.setPspSbmnSubmitterId(ev.target.value)}  value={this.props.amxPspState.pspSbmnSubmitterId} disabled={true}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm="4">Notas</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" onChange={ev => this.props.amxPsp.setNotes(ev.target.value)}  value={this.props.amxPspState.notes} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm="4">Crédito</Form.Label>
                        <Col sm="6">
                            <Form.Control type="number" onChange={ev => this.props.amxPsp.setMdaCredit(ev.target.value)}  value={this.props.amxPspState.mdaCredit} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm="4">Débito</Form.Label>
                        <Col sm="6">
                            <Form.Control type="number" onChange={ev => this.props.amxPsp.setMdaDebit(ev.target.value)}  value={this.props.amxPspState.mdaDebit} />
                        </Col>
                    </Form.Group>

                    <ButtonToolbar>
                        <ButtonGroup className="mr-2">
                            <Button variant='warning' disabled={!this.props.amxPspState.pspSENumber} onClick={this.saveFiid}>
                                Guardar
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup className="mr-2">
                            <Button variant='info' onClick={this.getFiids}>
                                Recargar Tabla
                            </Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Form>
            </Alert>

            <Alert>
                <Table striped bordered hover>
                    <thead className="thead-dark">
                    <tr>
                        <th>PSP SE Number</th>
                        <th>PSP ID</th>
                        <th>PSP Submitter ID</th>
                        <th>PSP Name</th>
                        <th>PSP SPC</th>
                        <th>PSP Country Code</th>
                        <th>PSP SBMN Submitter ID</th>
                        <th>Notes</th>
                        <th>Credit</th>
                        <th>Debit</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.dataTable()}
                    </tbody>
                </Table>
                {this.pagination()}
            </Alert>
        </>);
    }
}