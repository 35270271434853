import AppDispatcher from '../dispatchers/AppDispatcher';

export const SHOW_ENDPOINTS_PANEL = 'SHOW_ENDPOINTS_PANEL';
export const SHOW_ADMIN_PANEL = 'SHOW_ADMIN_PANEL';
export const SHOW_BALANCER_ACTION_CONFIRM_DIALOG = 'SHOW_BALANCER_ACTION_CONFIRM_DIALOG';
export const SET_LOGGED_USER = 'SET_LOGGED_USER';
export const SET_BALANCER_STATUS = 'SET_BALANCER_STATUS';
export const SHOW_REFUNDS_PANEL = 'SHOW_REFUNDS_PANEL';
export const SHOW_AUDIT_PANEL = 'SHOW_AUDIT_PANEL';
export const SHOW_AMX_PSP_PANEL = 'SHOW_AMX_PSP_PANEL';
export const SHOW_USER_READER_PANEL = 'SHOW_USER_READER_PANEL';
export const SHOW_B24_OPERATIONS_PANEL = 'SHOW_B24_OPERATIONS_PANEL';
export const SHOW_GSMF_OPERATIONS_PANEL = 'SHOW_GSMF_OPERATIONS_PANEL';

export function showGsmfOperationsPanel(isGsmfOperationsPanelShown) {
    AppDispatcher.dispatch({
        type: SHOW_GSMF_OPERATIONS_PANEL,
        isGsmfOperationsPanelShown: isGsmfOperationsPanelShown
    });
}

export function showB24OperationsPanel(isB24OperationsPanelShown) {
    AppDispatcher.dispatch({
        type: SHOW_B24_OPERATIONS_PANEL,
        isB24OperationsPanelShown: isB24OperationsPanelShown
    });
}

export function showEndpointsPanel(isEndpointsPanelShown) {
    AppDispatcher.dispatch({
        type: SHOW_ENDPOINTS_PANEL,
        isEndpointsPanelShown: isEndpointsPanelShown
    });
}

export function showAdminPanel(isAdminPanelShown) {
    AppDispatcher.dispatch({
        type: SHOW_ADMIN_PANEL,
        isAdminPanelShown: isAdminPanelShown
    });
}

export function showBalancerActionConfirmDialog(balancerAction) {
    AppDispatcher.dispatch({
        type: SHOW_BALANCER_ACTION_CONFIRM_DIALOG,
        balancerAction: balancerAction
    });
}

export function setLoggedUser(loggedUser) {
    AppDispatcher.dispatch({
        type: SET_LOGGED_USER,
        loggedUser: loggedUser
    });
}

export function setBalancerStatus(balancerStatus) {
    AppDispatcher.dispatch({
        type: SET_BALANCER_STATUS,
        balancerStatus: balancerStatus
    });
}

export function showRefundsPanel(isRefundsPanelShown) {
    AppDispatcher.dispatch({
        type: SHOW_REFUNDS_PANEL,
        isRefundsPanelShown: isRefundsPanelShown
    });
}

export function showAuditPanel(isAuditPanelShown) {
    AppDispatcher.dispatch({
        type: SHOW_AUDIT_PANEL,
        isAuditPanelShown: isAuditPanelShown
    });
}

export function showAmxPspPanel(isAmxPspPanelShown) {
    AppDispatcher.dispatch({
        type: SHOW_AMX_PSP_PANEL,
        isAmxPspPanelShown: isAmxPspPanelShown
    });
}

export function showUserReaderPanel(isUserReaderPanelShown) {
    AppDispatcher.dispatch({
        type: SHOW_USER_READER_PANEL,
        isUserReaderPanelShown: isUserReaderPanelShown
    });
}
