import AppDispatcher from "../dispatchers/AppDispatcher";

export const SET_TX_HISTORY = 'SET_TX_HISTORY';
export const SET_TRANSACTION_IDS = 'SET_TRANSACTION_IDS';
export const SET_PARTIAL_TX_HISTORY = 'SET_PARTIAL_TX_HISTORY';
export const SET_PARTIAL_TRANSACTION_IDS = 'SET_PARTIAL_TRANSACTION_IDS';
export const SET_FILE_TX_HISTORY = 'SET_FILE_TX_HISTORY';
export const SET_FILE_TRANSACTION_IDS = 'SET_FILE_TRANSACTION_IDS';
export const SET_REMOTE_TX_HISTORY = 'SET_REMOTE_TX_HISTORY';
export const SET_REMOTE_TRANSACTION_IDS = 'SET_REMOTE_TRANSACTION_IDS';
export const SET_RETENTIONS_TX_HISTORY = 'SET_RETENTIONS_TX_HISTORY';
export const SET_RETENTIONS_TRANSACTION_IDS = 'SET_RETENTIONS_TRANSACTION_IDS';
export const SHOW_CONFIRM_DIALOG = 'SHOW_CONFIRM_DIALOG';
export const SHOW_ALERT_ERROR = 'SHOW_ALERT_ERROR';

export function setTxHistory(refunds) {
    AppDispatcher.dispatch({
        type: SET_TX_HISTORY,
        refunds: refunds
    });
}

export function setTransactionIds(transactionIds) {
    AppDispatcher.dispatch({
        type: SET_TRANSACTION_IDS,
        transactionIds: transactionIds
    });
}

export function setPartialTxHistory(partialRefunds) {
    AppDispatcher.dispatch({
        type: SET_PARTIAL_TX_HISTORY,
        partialRefunds: partialRefunds
    });
}

export function setPartialTransactionIds(partialTransactionIds) {
    AppDispatcher.dispatch({
        type: SET_PARTIAL_TRANSACTION_IDS,
        partialTransactionIds: partialTransactionIds
    });
}

export function setFileTxHistory(fileRefunds) {
    AppDispatcher.dispatch({
        type: SET_FILE_TX_HISTORY,
        fileRefunds: fileRefunds
    });
}

export function setFileTransactionIds(fileTransactionIds) {
    AppDispatcher.dispatch({
        type: SET_FILE_TRANSACTION_IDS,
        fileTransactionIds: fileTransactionIds
    });
}

export function setRemoteTxHistory(remoteRefunds) {
    AppDispatcher.dispatch({
        type: SET_REMOTE_TX_HISTORY,
        remoteRefunds: remoteRefunds
    });
}

export function setRemoteTransactionIds(remoteTransactionIds) {
    AppDispatcher.dispatch({
        type: SET_REMOTE_TRANSACTION_IDS,
        remoteTransactionIds: remoteTransactionIds
    });
}

export function setRetentionsTxHistory(retentions) {
    AppDispatcher.dispatch({
        type: SET_RETENTIONS_TX_HISTORY,
        retentions: retentions
    });
}

export function setRetentionsTransactionIds(retentionsTransactionIds) {
    AppDispatcher.dispatch({
        type: SET_RETENTIONS_TRANSACTION_IDS,
        retentionsTransactionIds: retentionsTransactionIds
    });
}

export function showConfirmDialog(confirmDialog) {
    AppDispatcher.dispatch({
        type: SHOW_CONFIRM_DIALOG,
        confirmDialog: confirmDialog
    });
}

export function showAlertError(alertError) {
    AppDispatcher.dispatch({
        type: SHOW_ALERT_ERROR,
        alertError: alertError
    });
}