import {ReduceStore} from 'flux/utils';
import AppDispatcher from '../dispatchers/AppDispatcher';
import {
    SET_TX_HISTORY,
    SET_TRANSACTION_IDS,
    SHOW_CONFIRM_DIALOG,
    SHOW_ALERT_ERROR,
    SET_PARTIAL_TX_HISTORY,
    SET_PARTIAL_TRANSACTION_IDS,
    SET_REMOTE_TX_HISTORY,
    SET_REMOTE_TRANSACTION_IDS,
    SET_RETENTIONS_TX_HISTORY,
    SET_RETENTIONS_TRANSACTION_IDS,
    SET_FILE_TX_HISTORY,
    SET_FILE_TRANSACTION_IDS
} from "../actions/RefundsAction";

const stateCallbacks = (function () {
    function setTxHistory(state, action) {
        return Object.assign({}, state, {refunds: action.refunds});
    }

    function setTransactionIds(state, action) {
        return Object.assign({}, state, {transactionIds: action.transactionIds});
    }

    function setPartialTxHistory(state, action) {
        return Object.assign({}, state, {partialRefunds: action.partialRefunds});
    }

    function setPartialTransactionIds(state, action) {
        return Object.assign({}, state, {partialTransactionIds: action.partialTransactionIds});
    }

    function setFileTxHistory(state, action) {
        return Object.assign({}, state, {fileRefunds: action.fileRefunds});
    }

    function setFileTransactionIds(state, action) {
        return Object.assign({}, state, {fileTransactionIds: action.fileTransactionIds});
    }

    function setRemoteTxHistory(state, action) {
        return Object.assign({}, state, {remoteRefunds: action.remoteRefunds});
    }

    function setRemoteTransactionIds(state, action) {
        return Object.assign({}, state, {remoteTransactionIds: action.remoteTransactionIds});
    }

    function setRetentionsTxHistory(state, action) {
        return Object.assign({}, state, {retentions: action.retentions});
    }

    function setRetentionsTransactionIds(state, action) {
        return Object.assign({}, state, {retentionsTransactionIds: action.retentionsTransactionIds});
    }

    function showConfirmDialog(state, action) {
        return Object.assign({}, state, {confirmDialog: action.confirmDialog})
    }

    function showAlertError(state, action) {
        return Object.assign({}, state, {alertError: action.alertError})
    }

    return {
        [Symbol.for(SET_TX_HISTORY)]: setTxHistory,
        [Symbol.for(SET_TRANSACTION_IDS)]: setTransactionIds,
        [Symbol.for(SET_PARTIAL_TX_HISTORY)]: setPartialTxHistory,
        [Symbol.for(SET_PARTIAL_TRANSACTION_IDS)]: setPartialTransactionIds,
        [Symbol.for(SET_FILE_TX_HISTORY)]: setFileTxHistory,
        [Symbol.for(SET_FILE_TRANSACTION_IDS)]: setFileTransactionIds,
        [Symbol.for(SET_REMOTE_TX_HISTORY)]: setRemoteTxHistory,
        [Symbol.for(SET_REMOTE_TRANSACTION_IDS)]: setRemoteTransactionIds,
        [Symbol.for(SET_RETENTIONS_TX_HISTORY)]: setRetentionsTxHistory,
        [Symbol.for(SET_RETENTIONS_TRANSACTION_IDS)]: setRetentionsTransactionIds,
        [Symbol.for(SHOW_CONFIRM_DIALOG)]: showConfirmDialog,
        [Symbol.for(SHOW_ALERT_ERROR)]: showAlertError
    }
}());

const RefundsStore = class extends ReduceStore {
    constructor() {
        super(AppDispatcher);
        this.getInitialState = this.getInitialState.bind(this);
    }

    getInitialState() {
        return {
            refunds: undefined,
            transactionIds: undefined,
            partialRefunds: undefined,
            partialTransactionIds: undefined,
            fileRefunds: undefined,
            fileTransactionIds: undefined,
            remoteRefunds: undefined,
            remoteTransactionIds: undefined,
            retentions: undefined,
            retentionsTransactionIds: undefined,
            confirmDialog: undefined,
            alertError: undefined
        };
    }

    reduce(state, action) {
        if (stateCallbacks[Symbol.for(action.type)]) {
            return stateCallbacks[Symbol.for(action.type)](state, action);
        } else {
            return state;
        }
    }
};

export default new RefundsStore();