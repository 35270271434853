import {ReduceStore} from 'flux/utils';
import AppDispatcher from '../dispatchers/AppDispatcher';
import {configureLoggedUser} from "../utils/CoreHandlerUtils";
import {
    SET_BALANCER_STATUS,
    SET_LOGGED_USER,
    SHOW_ADMIN_PANEL,
    SHOW_AUDIT_PANEL,
    SHOW_BALANCER_ACTION_CONFIRM_DIALOG,
    SHOW_ENDPOINTS_PANEL,
    SHOW_REFUNDS_PANEL,
    SHOW_AMX_PSP_PANEL,
    SHOW_USER_READER_PANEL,
    SHOW_B24_OPERATIONS_PANEL,
    SHOW_GSMF_OPERATIONS_PANEL
} from "../actions/AppActions";

const stateCallbacks = (function () {
    function showGsmfOperationsPanel(state, action) {
        return Object.assign({}, state, {showGsmfOperationsPanel: action.isGsmfOperationsPanelShown});
    }

    function showB24OperationsPanel(state, action) {
        return Object.assign({}, state, {showB24OperationsPanel: action.isB24OperationsPanelShown});
    }

    function showEndpointsPanel(state, action) {
        return Object.assign({}, state, {showEndpointsPanel: action.isEndpointsPanelShown});
    }

    function showAdminPanel(state, action) {
        return Object.assign({}, state, {showAdminPanel: action.isAdminPanelShown});
    }

    function setLoggedUser(state, action) {
        let user = configureLoggedUser(action.loggedUser);
        return Object.assign({}, state, {loggedUser: user});
    }

    function setBalancerStatus(state, action) {
        return Object.assign({}, state, {balancerStatus: action.balancerStatus});
    }

    function showBalancerActionConfirmDialog(state, action) {
        return Object.assign({}, state, {balancerAction: action.balancerAction});
    }

    function showRefundsPanel(state, action) {
        return Object.assign({}, state, {showRefundsPanel: action.isRefundsPanelShown});
    }

    function showAuditPanel(state, action) {
        return Object.assign({}, state, {showAuditPanel: action.isAuditPanelShown});
    }

    function showAmxPspPanel(state, action) {
        return Object.assign({}, state, {showAmxPspPanel: action.isAmxPspPanelShown});
    }

    function showUserReaderPanel(state, action) {
        return Object.assign({}, state, {showUserReaderPanel: action.isUserReaderPanelShown});
    }

    return {
        [Symbol.for(SHOW_GSMF_OPERATIONS_PANEL)]: showGsmfOperationsPanel,
        [Symbol.for(SHOW_B24_OPERATIONS_PANEL)]: showB24OperationsPanel,
        [Symbol.for(SHOW_ENDPOINTS_PANEL)]: showEndpointsPanel,
        [Symbol.for(SHOW_ADMIN_PANEL)]: showAdminPanel,
        [Symbol.for(SHOW_BALANCER_ACTION_CONFIRM_DIALOG)]: showBalancerActionConfirmDialog,
        [Symbol.for(SET_LOGGED_USER)]: setLoggedUser,
        [Symbol.for(SET_BALANCER_STATUS)]: setBalancerStatus,
        [Symbol.for(SHOW_REFUNDS_PANEL)]: showRefundsPanel,
        [Symbol.for(SHOW_AUDIT_PANEL)]: showAuditPanel,
        [Symbol.for(SHOW_AMX_PSP_PANEL)]: showAmxPspPanel,
        [Symbol.for(SHOW_USER_READER_PANEL)]: showUserReaderPanel,
    }
}());

const AppStore = class extends ReduceStore {
    constructor() {
        super(AppDispatcher);
        this.getInitialState = this.getInitialState.bind(this);
    }

    getInitialState() {
        return {
            showEndpointsPanel: true,
            showAdminPanel: false,
            loggedUser: undefined,
            balancerStatus: undefined,
            balancerAction: undefined,
            showRefundsPanel: false,
            showAuditPanel: false,
            showAmxPspPanel: false,
            showUserReaderPanel: false,
            showB24OperationsPanel: false,
            showGsmfOperationsPanel: false
        };
    }

    reduce(state, action) {
        if (stateCallbacks[Symbol.for(action.type)]) {
            return stateCallbacks[Symbol.for(action.type)](state, action);
        } else {
            return state;
        }
    }
};

export default new AppStore();